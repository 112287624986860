// Detail Pages


.detail {

	.article-data {
		background-color: $light-gray;

		@include breakpoint(medium) {
			background-color: transparent;
		}

		.thumbnail {
			img {
				padding-top: $global-padding;

				@include breakpoint(medium) {
					padding-top: $global-padding*2;
				}
			}
		}

		ul {
			list-style: none;
			display: block;
			margin: 0 0 $global-margin 0;
			padding-top: 0;

			li {
				display: block;
				//border-bottom: 1px solid $medium-gray;

				&:last-child {
					border: 0;
				}

				.data {
					// padding: 8px 0;
					vertical-align: middle;
					// min-height: 30px;
				}

				label {
					display: inline-block;
					font-size: 100%;
					// padding-right: .25em;
					vertical-align: middle;
				}
			}
		}
	}

	.extras {
		margin: $global-margin 0;
		padding-top: $global-padding;
		border-top: 1px solid $medium-gray;

		@include breakpoint(medium) {
			margin: $global-margin*2 0;
		}
	}

	.article-detail {
		margin-top: $global-margin;
		margin-bottom: $global-margin*2;

		@include breakpoint(medium) {
			padding-top: 0;
		}

		.icon.pdf {
			color: get-color(warning);
			font-size: $icon-xsmall;
		}
	}
}