// Contacto


#contacto {
	.mapa {
		position: relative;
		margin-top: 0;
		margin-bottom: $global-margin*2;
		@include ratio1-1;

		iframe {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			margin: 0;
		}

		@include breakpoint(480px) {
			@include ratio3-2;
		}

		@include breakpoint(medium) {
			margin-top: -$global-margin;
			@include ratio5-2;
		}

		@include breakpoint(large) {
			margin-top: 0;
			margin-bottom: $global-margin*3;
		}
	}

	form {
		display: block;
		margin-bottom: $global-margin*2;
	}
}