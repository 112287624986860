// Main Menu


.main-menu--outer {
	position: absolute;
	top: 0;
	right: map-get($main-menu-width, small)*-1;
	padding: 0;
	height: 100%;
	background-color: $black;
	width: map-get($main-menu-width, small);

	@include breakpoint(medium) {
		right: map-get($main-menu-width, medium)*-1;
		width: map-get($main-menu-width, medium);
	}

	@include breakpoint(large) {
		position: relative;
		right: 0;
		margin-top: 0;
		background-color: $white;
		width: 100%;
	}
}


.main-menu {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	background-color: $black;

	&::before {
		position: absolute;
		@include triangle ( $icon-xxsmall/2, $white, right );
		top: (map-get($header-height, small)/2)-($icon-xxsmall/2);
		left: 0;
		margin: 0;
		padding: 0;
		z-index: 100;
	}

	@include breakpoint(medium) {
		&::before {
			top: (map-get($header-height, medium)/2)-($icon-xxsmall/2);
		}
	}

	@include breakpoint(large) {
		border-bottom: 1px solid $medium-gray;
		text-align: center;
		padding: .5em 0;
		background-color: transparent;

		&::before {
			display: none;
		}
	}

	& > .menu {
		display: block;
		width: 100%;
		text-align: left;
		margin: 0;
		padding: 0;

		@include breakpoint(large) {
			display: inline-block;
			width: auto;

			ul {
				width: auto;
			}
		}
	}

	li {
		border-top: 1px solid lighten($black, 10%);

		@include breakpoint(large) {
			padding: .25em;
			border: 0;
		}

		a {
			color: $white;
			padding-top: .9em;
			padding-bottom: 1em;
			font-weight: bold;
			line-height: 125%;

			&:hover {
				background-color: get-color(primary);
			}

			@include breakpoint(large) {
				color: $black;
				padding-top: .8em;
				padding-bottom: .8em;
				@include border-radius($global-radius);
				white-space: nowrap;
			}
		}

		&[aria-expanded="true"] {
			background-color: lighten($black, 5%);
		}

		li {
			border-bottom: 0;
			border-top: 1px solid lighten($black, 10%);

			@include breakpoint(large) {
				border: 0;
			}
	
			a {
				padding-left: $global-padding * 2;
				font-weight: normal;

				@include breakpoint(large) {
					padding: .8em $global-padding;
				}
			}
		}
	}

	@include cg-accordion;

	.is-dropdown-submenu-parent > a {
		padding-right: $accordionmenu-arrow-size * 1.7;
	}

	.menu-extras {
		padding: $global-padding;
		color: $white;
		font-size: $small-font-size;
	}

	.note {
		font-weight: normal;
	}
}









































