// PAGE BANNERS


#noticias, #detail-noticias {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, secondary);
	}
}

#articulos, #detail-articulos,
#entrevistas, #detail-entrevistas,
#bitacora,  #detail-bitacora {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, leeryreleer);
	}
}

#poesia, #detail-poesia,
#narrativa, #detail-narrativa,
#teatro, #detail-teatro {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, ficciones);
	}
}

#ojo-avizor, #detail-ojo-avizor {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, ojoavizor);
	}
}

#ilustradores {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, ilustradores);
	}
}

#enlaces {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, enlaces);
	}
}

#contacto {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, contacto);
	}
}

#premio, #detail-premio,
#donaciones,
#al {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, primary);
	}
}

#fundacion {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, quienesomos);
	}
}

#asinosven {
	.page-banner--small {
		background-color:  darken(map-get($banner-bkg-color, asinosven), 20%);
	}
}

#buscar {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, buscar);
	}
}

#podcast,
#detail-podcast {
	.page-banner--small {
		background-color: darken(map-get($banner-bkg-color, podcast), 20%);
	}
}

#detail-evento {
	.page-banner--small {
		background-color: map-get($banner-bkg-color, eventos);
	}
}



// Banner default
%banner-text-color {
	@include breakpoint(medium) {
		.inner {
			&.white {
				* {
					color: $white;
				}
			}

			&.black {
				* {
					color: $black;
				}
			}
		}
	}

	@include breakpoint(768px) {
		.inner {
			&.white {
				.bar {
					.subtitle {
						border-left-color: $white !important;
					}
				}
			}

			&.black {
				.bar {
					.subtitle {
						border-left-color: $black !important;
					}
				}
			}
		}
	}
}


// Banner text-size
%banner-small-text-size {
	@include breakpoint(medium) {
		.title {
			font-size: 3rem;
			padding: 0 $icon-medium*2;
			@include text-shadow-full(0, 0, 10px, $black);
		}

		.desc {
			padding: 0 $icon-medium*2;
			@include text-shadow-full(0, 0, 10px, $black);
		}
	}

	@include breakpoint(large) {
		//max-width: rem-calc((map-get($breakpoints, large)*70)/100);
		.title {
			font-size: 3.85rem;
		}

		.desc {
			font-size: 120%;
			padding: 0 $icon-medium*4;
		}
	}

	@include breakpoint(xlarge) {
		.title {
			font-size: 4.25rem;
			padding: 0 $icon-medium*4;
		}

		.desc {
			font-size: 150%;
			padding: 0 20%;
		}
	}
}

%banner-large-text-size {
	@include breakpoint(medium) {
		.title {
			font-size: 3rem;
			padding: 0 $icon-medium*2;
			@include text-shadow-full(0, 0, 10px, $black);
		}

		.desc {
			font-size: 150%;
			line-height: 1.25;
			padding: 0 $icon-medium*2;
			padding-top: .5em;
			@include text-shadow-full(0, 0, 10px, $black);
		}
	}

	@include breakpoint(large) {
		//max-width: rem-calc((map-get($breakpoints, large)*70)/100);
		.title {
			font-size: 3.85rem;
		}

		.desc {
			font-size: 175%;
			padding: 0 $icon-medium*4;
			padding-top: .5em;
		}
	}

	@include breakpoint(xlarge) {
		.title {
			font-size: 5rem;
			padding: 0 $icon-medium*4;
		}

		.desc {
			font-size: 220%;
			padding: 0 20%;
			padding-top: .5em;
		}
	}
}


// Banner button size
%banner-button-size {
	@include button-expand(true);
	text-transform: uppercase;
	font-weight: bold;
	color: $white;
	margin: 0;

	@include breakpoint(medium) {
		@include button-expand(false);
		font-size: 110%;
		padding: .8em 1.5em;
	}

	@include breakpoint(large) {
		font-size: 130%;
	}

	@include breakpoint(xlarge) {
		font-size: 150%;
	}
}


.page-banner--small {
	position: relative;
	display: block;
	height: map-get($banner-height, xsmall);

	@include breakpoint(medium) {
		height: map-get($banner-height, small);
	}

	&:before {
		content: " ";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin: 0;
		padding-top: 0;
		background-image: url(../img/texturaBkg.png);
		background-repeat: repeat;
	}

	> .inner {
		width: 100%;
		@include absolute-center;
		text-align: center;
		z-index: 10;
		background-color: transparent;

		@include breakpoint(medium) {
			width: 70%;
		}

		.page-title {
			h1,
			.title,
			.subtitle {
				display: block;
				padding: 0 $global-padding;

				@include breakpoint(768px) {
					padding: $global-padding/2 $global-padding;
				}
			}
			
			.subtitle {
				text-transform: uppercase;
				font-family: $body-font-family;
			}

			&.bar {
				@include breakpoint(768px) {
					h1,
					.title,
					.subtitle {
						display: inline-block;
					}

					.subtitle {
						font-size: rem-calc(35px);
						border-left: 1px solid $black;
					}
				}
			}
		}

		.page-desc {
			display: inline-block;
			padding: 0;
			margin-bottom: 0;
			color: $white;
			font-weight: normal;
			line-height: 1.25em;

			@include breakpoint(768px) {
				font-size: rem-calc(20px);
				max-width: 700px;
			}

			@include breakpoint(xlarge) {
				font-size: rem-calc(20px);
			}
		}
	}

	@extend %banner-text-color;

	@extend %banner-small-text-size;

	.banner-gato {
		display: none;

		@include breakpoint(medium) {
			position: absolute;
			display: block;
			bottom: 0;
			right: .5em;
			height: 50%;
			z-index: 1;
		}

		@include breakpoint(large) {
			height: 60%;
		}

		@include breakpoint(xlarge) {
			height: 65%;
		}
	}
}



// Banner large with carousel
.orbit {
	.orbit-previous,
	.orbit-next {
		font-size: $icon-medium;
	}
}

%large-banner-height {
	height: map-get($banner-height, small);

	@include breakpoint(medium) {
		height: map-get($banner-height, medium);
	}

	@include breakpoint(large) {
		height: map-get($banner-height, large);
	}

	@include breakpoint(xlarge) {
		height: map-get($banner-height, xlarge);
	}
}

.page-banner--large {
	background-color: $white;

	&.orbit {
		padding-bottom: 2em;
	}

	& > .inner {
		@extend %large-banner-height;
	}

	.orbit-bullets {
		position: absolute;
		width: 100%;
		bottom: 0;

		@include breakpoint(medium) {
			margin-bottom: .2rem
		}
	}

	.orbit-previous,
	.orbit-next {
		top: 0;
		@include translate(0, (map-get($banner-height, small)/2) - $global-padding - ($icon-medium/2));

		@include breakpoint(medium) {
			top: 50%;
			@include translate(0, -50%);
		}
	}

	.orbit-slide,
	.slide {
		.orbit-image,
		.slide-image {
			display: block;
			width: 100%;
			background-color: $light-gray;
			@extend %large-banner-height;
		}

		.orbit-caption,
		.slide-caption {
			position: relative;
			background-color: transparent;
			color: $black;

			@include breakpoint(medium) {
				width: 100%;
				@include vertical-center;
				text-align: center;

				&.bottom {
					top: auto;
					bottom: 5rem;
					transform: translate(-50%, -100%);
				}

				.inner {
					width: 100%;
					display: block;
					@include absolute-center;
					// background-color: transparentize($black, 0.65);
				}
			}

			@extend %banner-text-color;

			@extend %banner-large-text-size;


			.button {
				@extend %banner-button-size;
			}
		}
	}
}

