// PAGE WRAPPER


html,
body {
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
}

body {
	height: auto;
	min-height: 100%;
}


%wrapper {
	position: absolute;
	display: block;
	width: 100%;
	height: auto;
	min-height: 100%;
	margin: 0;
	padding: 0;
}

.wrapper {
	@extend %wrapper;
	overflow-x: hidden;
}


.wrapper-inner {
	@extend %wrapper;
	min-width: 320px;

	@include breakpoint(medium down) {
		@include transition(0.4s);
	}

	&.active {
		@include translate((map-get($main-menu-width, small))*-1, 0);

		@include breakpoint(medium) {
			@include translate((map-get($main-menu-width, medium))*-1, 0);
		}

		@include breakpoint(large) {
			@include transform(none);
		}
	}
}


main {
	background-color: $white;
	padding-bottom: $global-padding*2;
}

.section-footer {
	padding-top: $global-padding;
	border-top: 1px solid $medium-gray;

	.button {
		width: 100%;

		@include breakpoint(medium) {
			width: auto;
		}
	}
}
























