// Buscar



#buscar {
	.criterio-busqueda {
		margin-top: $global-margin;
		margin-bottom: $global-margin;

		.total-resultados {
			padding-top: $global-padding/2;
			padding-bottom: $global-padding;
			color: $dark-gray;
		}
	}

	.buscar-categoria {
		margin-top: $global-margin;
		margin-bottom: 0;
		text-transform: uppercase;
	}
}