// Reference


.reference {
	display: block;
	width: 100%;
	position: relative;
	margin: $global-padding*1.3 0 0 0;
	// background-color: red;

	a {
		display: inline;
		position: relative;
		text-transform: uppercase;
		color: $black;
		@include clearfix;
		
		span {
			vertical-align: middle;
		}

		.icon {
			color: $primary-color;
			font-size: $icon-xsmall;
			vertical-align: middle;
			display: inline-block;
			// float: right;
			margin: rem-calc(4px) 0 0 rem-calc(6px);

			.pointer {
				position: absolute;
				display: block;
				z-index: 10;
				&::before {
					@include triangle ( $icon-xxsmall/2, $primary-color, down );
					margin: rem-calc(-37px) 0 0 (($icon-xsmall/2)-($icon-xxsmall/2));
				}
			}
		}

		&:hover {
			.icon {
				color: $primary-color-hover;
			}
		}
	}

	.item &,
	.article-data & {
		margin: 0;
		
		a {
			text-transform: none;
		}
	}

	.item & {
		a {
			color: $dark-gray;
		}
	}

	.article-data & {
		display: inline-block;
	}

	#ilustradores & {
		padding-top: 0;
	}

	// Referece Active state
	&.active {
		z-index: 999;

		a {
			z-index: 1001;
		}
		
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			margin: -1rem 0 0 0;
			padding: 10px 0;
			width: 100%;
			height: 100%;
			// background-color:green;
		}
	}
}


// Referencia en columna izquierda de página de detalle
.accordion-info {
	.reference {
		margin-top: 0;
	}
}

.reference-dropdown {
	position: absolute;
	display: block;
	margin: 0;
	z-index: 1000;
	width: 100%;
	height: 1px;
	height: 0;
	overflow: visible;
	min-width: map-get($dropdown-sizes, small);
	max-width: map-get($dropdown-sizes, large);
	padding: 0 $global-padding;

	&.vfit {
		left: auto;
		right: 0;
	}

	@include breakpoint(rem-calc($dropdown-width) + ($global-padding*2)) {
		min-width: $dropdown-width;
		padding-left: 0;
	}

	.inner {
		position: absolute;
		display: block;
		width: 100%;
		left: 0;
		bottom: ($icon-xxsmall/2) - rem-calc(1px);
		min-height: rem-calc(100px);
		height: auto;
		padding: ($dropdown-padding - $drop-down-border-bottom-width) $dropdown-padding $dropdown-padding $dropdown-padding;
		background-color: $dropdown-background;
		border: $dropdown-border;
		border-bottom: $drop-down-border-bottom-width solid $primary-color;
		font-size: $dropdown-font-size;
		@include border-radius($dropdown-radius);
		@include box-shadow(1.3rem);
	}

	.title,
	.desc {
		line-height: 1.5;
		padding: 0;
		margin: 0;
	}

	.title {
		font-weight: bold;
		color: $black;
	}

	.desc {
		font-size: $small-font-size !important;
		text-align: left !important;
		color: $black;
		@include clearfix;

		div, span, em, ul, ol, li, p, b, strong, i, a {
			font-size: 1em !important;
			text-align: left !important;
			margin: 0 !important;
			padding: 0 !important;
			line-height: $paragraph-lineheight !important // 1.5;
		}
	}

	img {
		float: right;
		max-width: rem-calc(100px);
		max-height: rem-calc(80px);
		margin: $drop-down-border-bottom-width 0 $global-padding/2 $global-padding/2;
	}

	// Pointer inside reference details overlay
	// .pointer {
	// 	position: absolute;
	// 	display: block;
	// 	z-index: 10;
	// 	&::before {
	// 		@include triangle ( $icon-xxsmall/2, $primary-color, down );
	// 		margin: rem-calc(-12px) 0 0 0;
	// 	}
	// }
}


.data {
	.reference {
		display: inline-block;
		width: auto;
	}

	.reference-dropdown {
		&.vfit {
			left: 0;
		}
	}
}
