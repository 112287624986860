@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

// $slick-font-path: "./fonts/" !default;
// $slick-font-family: "slick" !default;
// $slick-loader-path: "./" !default;
// $slick-arrow-color: white !default;
// $slick-dot-color: black !default;
// $slick-dot-color-active: $slick-dot-color !default;
// $slick-prev-character: "\2190" !default;
// $slick-next-character: "\2192" !default;
// $slick-dot-character: "\2022" !default;
// $slick-dot-size: 6px !default;
// $slick-opacity-default: 0.75 !default;
// $slick-opacity-on-hover: 1 !default;
// $slick-opacity-not-active: 0.25 !default;


$slick-font-path: "../fonts/" !default;
$slick-font-family: "cg" !default;
$slick-loader-path: "../img/" !default;
$slick-arrow-color: $black !default;
$slick-dot-color: $black !default;
$slick-dot-color-active: get-color(primary) !default;
$slick-prev-character: $cg-icon-left; // "\2190" !default;
$slick-next-character: $cg-icon-right; // "\2192" !default;
$slick-dot-character: $cg-icon-circle; // "\2022" !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 1 !default; // 0.75
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	}
	@else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	@include breakpoint (400px) {
		border-top: 1px solid $medium-gray;
		border-bottom: 1px solid $medium-gray;

		&:before {
			content: " ";
			position: absolute;
			width: $global-padding;
			height: 100%;
			top: 0;
			left: 0;
			background-color: $white;
			z-index: 100;
		}
	}

	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "cg" {
	@font-face {
		font-family: "cg";
		src: slick-font-url("cg.eot");
		src: slick-font-url("cg.eot?#iefix") format("embedded-opentype"), slick-font-url("cg.woff") format("woff"), slick-font-url("cg.ttf") format("truetype"), slick-font-url("cg.svg#slick") format("svg");
		// src: slick-font-url("slick.eot");
		// src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: $icon-medium;
	width: $icon-medium;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparentize($white, .5);
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	margin: 0;
	border: none;
	outline: none;
	z-index: 101;

	@include breakpoint(xlarge) {
		width: $global-padding * 3;
	}

	@include breakpoint(xxlarge) {
		width: $global-padding * 6;
	}

	&:hover, &:focus {
		outline: none;
		//background: transparent;
		color: transparent;

		&:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}

	&:before {
		font-family: $slick-font-family;
		font-size: $icon-medium;
		line-height: 1;
		color: $slick-arrow-color;
		//opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}


}

.slick-prev {
	left: 0;

	[dir="rtl"] & {
		left: auto;
		right: 0;
	}

	&:before {
		content: $slick-prev-character;
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: 0;

	[dir="rtl"] & {
		left: 0;
		right: auto;
	}

	&:before {
		content: $slick-next-character;
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 0;
	padding-bottom: $global-padding*2.5;
}

.slick-dots {
	position: absolute;
	bottom: rem-calc(10px);
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	left: 0;

	li {
		position: relative;
		display: inline-block;
		height: 10px;
		width: 10px;
		margin: 5px;
		padding: 0;
		cursor: pointer;

		button {
			border: 0;
			background: transparent;
			display: block;
			height: 10px;
			width: 10px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 0;
			cursor: pointer;

			&:hover, &:focus {
				outline: none;

				&:before {
					opacity: $slick-opacity-on-hover;
					color: $slick-dot-color-active;
				}
			}

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				width: 10px;
				height: 10px;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 10px;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}

		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}
