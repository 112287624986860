// Asi nos Ven Custom CSS


#enlaces {
	.accordion-title {
		position: relative;
		padding-left: $icon-medium + .5rem;

		.icon {
			position: absolute;
			top: 0;
			left: 0;
			font-size: $icon-small;
			padding: $accordion-item-padding;
			margin: ($icon-small/-4) 0 0 0;
		}
	}
}