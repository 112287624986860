// Responsive Detail Content

.detail-content {

	// Tabs links
	.tabs {
		@include breakpoint(large) {
			@include grid-column(1 of 4);
			padding-left: 0 !important;
		}

		.accordion-links {
			@include tabs-title;
			padding-top: $global-padding;
			margin-top: $global-margin;
		}

		.accordion-info {
			padding-left: 0;
			padding-right: 0;
			border-bottom: 1px $medium-gray solid;
		}
	}


	// Tabs content
	.tabs-content {
		@include breakpoint(large) {
			@include grid-column(3 of 4);
		}
	}


	// Acordion
	> .accordion {
		@include breakpoint(medium) {
			margin-top: -1rem;
		}

		> .accordion-item {
			> .accordion-title {
				font-weight: bold;
			}

			&:first-child {
				.accordion-title {
					border-top: 0;
				}
			}
		}
	}

	.accordion {
		.accordion-item > .accordion-title::after {
			color: $black;
		}

		.accordion-links {
			padding: $global-padding 0;

			a {
				display: block;
				padding: $global-padding/4 $global-padding ;
			}
		}

		.accordion-info {
			padding-left: $global-padding;
			padding-right: $global-padding;
		}
	}

	.accordion-links {
		border-top: 1px $medium-gray solid;
		width: 100% !important;

		a {
			color: $secondary-color !important;
		}
	}

	.accordion-info {
		.reference {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}


.accordion {
	width: 100%;
	@include cg-accordion;
}
