// MIXINS
// [att^=val] – the “begins with” selector
// [att$=val] – the “ends with” selector
// [att*=val] – the “contains” selector


// Opacity
@mixin opacity($opacity) {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacity*100 + ")";
	filter: alpha(opacity = $opacity*100);
	-moz-opacity: $opacity;
	-khtml-opacity: $opacity;
	opacity: $opacity;
}


// Generic Transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale ($scale) {
	@include transform(scale($scale));
} 

// translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin ($origin) {
	-moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}


// Transition
@mixin transition($time) {
	-webkit-transition: All $time ease;
	-moz-transition: All $time ease;
	-o-transition: All $time ease;
	transition: All $time ease;
}


// Border radius
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-top-right-radius($radius) {
	-webkit-border-top-right-radius: $radius;
	-moz-border-radius-topright: $radius;
	border-top-right-radius: $radius;
}

@mixin border-top-left-radius($radius) {
	-webkit-border-top-left-radius: $radius;
	-moz-border-radius-topleft: $radius;
	border-top-left-radius: $radius;
}

@mixin border-bottom-right-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-radius-bottomright: $radius;
	border-bottom-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-radius-bottomleft: $radius;
	border-bottom-left-radius: $radius;
}


// Aspect ratio
@mixin ratio() {
	display: block;
	height: 0;
	overflow: hidden;	
}

//** Cuadrado
@mixin ratio1-1() {
	@include ratio;
	padding:0 0 100% 0;
}

//** Rectangulo 2x1 Vertical
@mixin ratio1-2() {
	@include ratio;
	padding:0 0 200% 0;
}

//** Rectangulo 2x1 horizontal
@mixin ratio2-1() {
	@include ratio;
	padding:0 0 50% 0;
}

@mixin ratio2-3() {
	@include ratio;
	padding:0 0 150% 0;
}

//** Rectangulo 2x1 Vertical
@mixin ratio3-2() {
	@include ratio;
	padding:0 0 66.6666% 0;
}

@mixin ratio4-3() {
	@include ratio;
	padding:0 0 75% 0;
}

@mixin ratio5-2() {
	@include ratio;
	padding:0 0 40% 0;
}

@mixin ratio9-16() {
	@include ratio;
	padding:0 0 177.777% 0;
}

@mixin ratio16-7() {
	@include ratio;
	padding:0 0 43.75% 0;
}

@mixin ratio16-9() {
	@include ratio;
	padding:0 0 56.25% 0;
}

@mixin ratio23-8() {
	@include ratio;
	padding:0 0 34.782% 0;
}

//** Free Aspect ratio
@mixin ratio-reset() {
	display: inherit;
	height: inherit;
	overflow: visible;
	padding: inherit;
}


// Posicion
@mixin floatLeft() { float:left; }
@mixin floatRight() { float:right; }
@mixin absLeft() { left:0; }
@mixin absRight() { right:0; }



// Change box model
@mixin box-sizing() {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@mixin box-sizing-reset() {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}


// Adjust "inline-block" display property
@mixin inline-block() {
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: top;
	zoom: 1;
	*display: inline;
}


// Button sizes
@mixin button-large() {
	width: 138px;
	height: 138px;
	@include border-radius(70px);
}

@mixin button-small() {
	width: 88px;
	height: 88px;
	@include border-radius(45px);
	@include text-small;	
}


// Box-shadow
@mixin box-shadow($size) {
	-moz-box-shadow: 0 0 $size rgba(0,0,0,.25);
	-webkit-box-shadow: 0 0 $size rgba(0,0,0,.25);
	box-shadow: 0 0 $size rgba(0,0,0,.25);
}

@mixin box-shadow-full($x, $y, $size, $color, $transp) {
	-moz-box-shadow: $x $y $size rgba($color,$transp);
	-webkit-box-shadow: $x $y $size rgba($color,$transp);
	box-shadow: $x $y $size rgba($color,$transp);
}

@mixin box-shadow-reset() {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}


// Text shadow
@mixin text-shadow() { text-shadow: 0 0 .2em $black; }

@mixin text-shadow-hi() { text-shadow: 0 0 .2em $dark-gray; }

@mixin text-shadow-med() { text-shadow: 0 0 .2em $medium-gray; }

@mixin text-shadow-low() { text-shadow: 0 0 .2em $light-gray; }

@mixin text-shadow-crisp() { text-shadow: 0 0 4px $black; }

@mixin text-shadow-reset() { text-shadow: none; }

@mixin text-shadow-full($x, $y, $size, $color) {
	text-shadow: $x $y $size $color;
}


// Thumbnails
@mixin thum() {
	position: relative;
	display: block;
	margin: 0;
	border: 0 none;
}

@mixin thum-full() {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	@include border-radius(0);
}


// Non selectable
@mixin non-selectable() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


// Triangles
@mixin triangle ( $t-size, $t-color, $t-direction ) {
  display: block;
  width: 0;
  height: 0;
  border: inset $t-size;
  content: '';

  @if ($t-direction == down) {
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $t-color transparent transparent;
  }
  @if ($t-direction == up) {
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent $t-color;
  }
  @if ($t-direction == right) {
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent $t-color;
  }
  @if ($t-direction == left) {
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent $t-color transparent transparent;
  }
}


// Placeholder
@mixin placeholder ( $placeholder-color, $placeholder-size ) {
	&::placeholder {
		color: $placeholder-color;
		font-size: $placeholder-size;
	}
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $placeholder-color;
		font-size: $placeholder-size;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		color: $placeholder-color;
		font-size: $placeholder-size;
	}
	// $placeholder-parent::-ms-input-placeholder { /* IE 10+ */
	// 	color: $placeholder-color;
	// 	font-size: $placeholder-size;
	// }
	// $placeholder-parent::-moz-placeholder { /* Firefox 18- */
	// 	color: $placeholder-color;
	// 	font-size: $placeholder-size;
	// }
}


// Triangle
@mixin cg-triangle(
	$triangle-size,
	$triangle-color,
	$triangle-direction
) {
	display: block;
	width: $triangle-size;
	height: $triangle-size;
	font-size: $triangle-size;
	line-height: $triangle-size;
	color: $white;

	@if ($triangle-direction == down) {
		content: $cg-icon-bottom-small;
	}
	@if ($triangle-direction == up) {
		content: $cg-icon-top-small;
	}
	@if ($triangle-direction == right) {
		content: $cg-icon-right-small;
	}
	@if ($triangle-direction == left) {
		content: $cg-icon-left-small;
	}

	@include breakpoint(large) {
		color: $triangle-color;
	}
}


@mixin cg-accordion {
	.is-accordion-submenu-parent > a,
	.is-dropdown-submenu-parent > a,
	.accordion-item > .accordion-title {
		position: relative;

		&::after {
			font-family: 'cg';
			@include cg-triangle($accordionmenu-arrow-size, $accordionmenu-arrow-color, down);
			position: absolute;
			top: 50%;
			margin-top: -1 * ($accordionmenu-arrow-size / 2);
			right: $global-margin * .5;
			@include transition(.25s);
		}
	}

	.is-accordion-submenu-parent[aria-expanded='true'] > a::after,
	.is-dropdown-submenu-parent.is-active > a::after,
	.accordion-item.is-active > .accordion-title::after {
		@include rotate (180);
		@include transform-origin (50% 50%);
	}
}


// Column size
@mixin std {
	max-width: $std-width;
}





