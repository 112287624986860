// Carrusel


.carrusel {
	background-color: $white;
	display: block;
	overflow: hidden;

	@include breakpoint(medium down) {
		padding-left: 0;
		padding-right: 0;
	}

	.item {
		position: relative;
		float: left;
		width: 100%;
		padding: $global-padding $global-padding 0 $global-padding;

		@include breakpoint(400px) {
			width: 50%;

			&.slick-active:before {
				content: " ";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 200%;
				width: 1px;
				background-color: $medium-gray;
			}
		}

		@include breakpoint(medium) {
			width: 33.333%;
		}

		@include breakpoint(large) {
			width: 25%;
		}

		@include breakpoint(xxlarge) {
			width: 20%;
		}

		.thumb {
			.inner {
				@include ratio1-1;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center bottom;
				margin-bottom: rem-calc(10px);

				@include breakpoint(400px) {
					background-position: left bottom;
				}
			}
		}

		.item-data {
			text-align: center;

			@include breakpoint(400px) {
				text-align: left;
			}
		}
	}
}