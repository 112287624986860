// Borders


[class*=" b-"] {
	border: 0;
	border-style: solid;
}

.b-t {
	border-top-width: 1px; 
}

.b-b {
	border-bottom-width: 1px; 
}

.b-l {
	border-left-width: 1px; 
}

.b-r {
	border-right-width: 1px; 
}

.b-white {
	border-color: $white;
}

.b-light-gray {
	border-color: $light-gray;
}

.b-medium-gray {
	border-color: $medium-gray;
}

.b-dark-gray {
	border-color: $dark-gray;
}

.b-black {
	border-color: $black;
}