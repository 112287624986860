// Magnific Popup - Custom Styles


.mfp-bg {
	min-width: rem-calc(320px);
}

.mfp-content {
	min-width: rem-calc(290px);
	text-align: center;
}


// Bottom bar
.mfp-bottom-bar {
	margin-top: rem-calc(15px);

	.mfp-title {
		text-align: left;

		.title {
			font-weight: bold;
		}

		small {
			font-weight: normal;
		}
	}
}


// Close button
.mfp-close {
	position: fixed;
	display: block;
	top: 0;
	left: 50%;
	margin: 0 0 0 rem-calc(-25);
	padding: 0;
	width: rem-calc(50) !important;
	height: rem-calc(50);

	@include breakpoint(large) {
		left: auto;
		right: 0 !important;
	}

	&:before {
		left: 0;
		padding: rem-calc(10);
		font-size: rem-calc(30);
		color: $white;
		background-color: $primary-color;
		width: 100%;
		height: 100%;
	}

	&:hover {
		&:before {
			background-color: $anchor-color-hover;
		}
	}
}


// Left and Right Arrows

// 	.mfp-arrow-left,
// 	.mfp-arrow-right {

// 		&:after,
// 		&:before {
// 			display: none;
// 			border: 0;
// 			margin: 0;
// 		}

// 		.icon {
// 		background-color: green;
// 			position: absolute;
// 			display: block;
// 			font-size: rem-calc(50);
// 			width: rem-calc(90);
// 			height: rem-calc(110);
// 			color: $blue-color;
// 		}

// 		&:hover {
// 			.icon {
// 				color: $anchor-color-hover;
// 			}
// 		}
// 	}


// Cursors
.mfp-zoom-out-cur {
  &, .mfp-image-holder .mfp-close {
	cursor: default;
  }
}

.mfp-zoom {
	cursor: default;
}




