// Fundacion Cuatrogatos


#fundacion {

	.junta {
		.article-row-section {
			margin-top: 2rem;
		}
	}

	.folletos {
		.folletos-lista {
			margin-top: 2rem;

			.desc {
				color: $black;
			}
		}
	}

}


