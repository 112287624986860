// Recaptcha


.g-recaptcha {
	@include transform-origin(left);
	@include scale(.954);

	@include breakpoint(400px) {
		@include scale(1);
	}
}