// Header Menu


.page-header--menu {
	float: right;

	.menu-option {
		vertical-align: middle;
		line-height: $icon-xsmall;
		height: map-get($header-height, small);
		padding: 0 $global-padding;
		cursor: pointer;

		@include breakpoint(medium) {
			min-width: map-get($header-height, medium);
			height: map-get($header-height, medium);
			text-align: center;
		}

		@include breakpoint(large) {
			min-width: auto;
			height: map-get($header-height, large);
		}

		a,
		a span {
			display: inline-block;
			height: map-get($header-height, small);
			line-height: map-get($header-height, small);
			color: $black;

			@include breakpoint(medium) {
				height: map-get($header-height, medium);
				line-height: map-get($header-height, medium);
			}

			@include breakpoint(large) {
				height: map-get($header-height, large);
				line-height: map-get($header-height, large);
			}
		}
	}

	.icon {
		display: inline-block;
		height: $icon-xsmall;
		font-size: $icon-xsmall;
		vertical-align: middle;
		line-height: map-get($header-height, small);

		@include breakpoint(medium) {
			line-height: map-get($header-height, medium);
		}

		@include breakpoint(large) {
			line-height: map-get($header-height, large);
		}
	}
}


//-- Language
[data-multilang="false"] {
	.lang-toggle {
		display: none;
	}
}

[data-multilang="true"] {
	.lang-toggle {
		float: left;
		border-right: 1px solid $light-gray;
	}
}

.lang-toggle {
	.icon {
		margin-top: -.15em;
	}

	span {
		display: none !important;

		@include breakpoint(medium) {
			display: inline-block !important;
		}
	}
}


//--Fundación Cuatrogatos link
.fundacion-link {
	display: none;

	@include breakpoint(large) {
		display: block;
		float: left;
	}

	a {
		text-align: right;

		@include breakpoint(large) {
			padding-top: map-get($header-height, large)/2;
			margin-top: ($fundacion-link-font-size + $text-note)/-1.5;
		}

		h4 {
			color: $primary-color;
			font-size: $fundacion-link-font-size;
			line-height: $fundacion-link-font-size * .75;
		}

		&:hover {
			h4 {
				color: $primary-color-hover;
			}
		}
	}
}


//-- Buscar
.search-toggle {
	float: left;
	border-left: 1px solid $light-gray;
	border-right: 1px solid $light-gray;
	
	.search-open {
		display: block;
	}

	.search-close {
		display: none;
	}

	&.active {
		.search-open {
			display: none;
		}

		.search-close {
			display: block;
		}
	}

	@include breakpoint(medium) {
		border-right: 0;
	}

	@include breakpoint(large) {
		display: none;
	}
}

.search-form {
	position: absolute;
	display: block;
	width: map-get($search-form-width, small);
	margin: 0;
	top: 0;
	right: 0;

	@include breakpoint(medium) {
		width: map-get($search-form-width, medium);
	}

	@include breakpoint(large) {
		position: relative;
		float: left;
		top: 0;
		left: auto;
		background-color: transparent;
		width: auto;
		padding-left: 0;
		border-left: 1px solid $light-gray;
	}

	&::before {
		content: "";
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		margin: 0;
		width: 100%;
		height: map-get($header-height, small);
		background-color: $white;

		@include breakpoint(medium) {
			height: map-get($header-height, medium);
		}

		@include breakpoint(large) {
			display: none;
		}
	}

	form {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		padding: $global-padding;
		background-color: $black;
		@include transition(.4s);

		&::before {
			position: absolute;
			@include triangle ( $icon-xxsmall/2, $white, down );
			top: 0;
			right: rem-calc(76px);
			margin: 0;
			padding: 0;
			z-index: 100;
		}

		label {
			display: none;
		}
		
		.search-box {
			width: 100%;
			height: 100%;
			padding-right: $icon-xsmall * 2;
			margin: 0;
		}
		
		.search-btn {
			position: absolute;
			right: $icon-xsmall;
			top: 0;
			z-index: 1;

			&:hover {
				color: $primary-color;
			}

			.icon {
				margin-top: 0;

				@include breakpoint(large) {
					margin-top: 0;
				}
			}
		}

		@include breakpoint(medium) {
			margin-top: rem-calc(-12px);

			&::before {
				right: (map-get($search-form-width, medium)/2) - ($icon-xxsmall/2);
			}
		}

		@include breakpoint(large) {
			margin-top: 0;
			position: relative;
			padding: 0;
			background-color: transparent;
			width: 7em;

			&::before {
				display: none;
			}

			.search-box {
				border: 0;
				box-shadow: none;
				padding: ((map-get($header-height, large) - rem-calc(26px))/2) 0;
				font-size: $buscar-link-font-size;
				@include placeholder("green", $buscar-link-font-size);
			}
		
			.search-btn {
				right: 0;
			}
		}
	}

	&.active {
		form {
			margin-top: map-get($header-height, small);

			@include breakpoint(medium) {
				margin-top: map-get($header-height, medium);
			}

			@include breakpoint(large) {
				margin-top: 0;
				width: map-get($search-form-width, large);
			}
		}
	}
}


//-- Donar
.donar {
	display: none;

	@include breakpoint(medium) {
		display: block;
		float: left;
		background-color: $primary-color;
		letter-spacing: -.025em;
		padding: 0 !important;

		a {
			color: $white !important;
			font-weight: bold;
			text-transform: uppercase;
			font-size: $donar-link-font-size;
		}
	}

	@include breakpoint(medium) {
		padding: 0 $global-padding*2 !important;

		&:hover {
			background-color: $primary-color-hover;
		}
	}
}


//-- Menu Toggle
.menu-toggle {
	float: left;

	.menu-open {
		display: block;

		.wrapper-inner.active & {
			display: none;
		}
	}

	.menu-close {
		display: none;

		.wrapper-inner.active & {
			display: block;
		}
	}

	// &.active {
	// 	.search-open {
	// 		display: none;
	// 	}

	// 	.search-close {
	// 		display: block;
	// 	}
	// }

	@include breakpoint(large) {
		display: none;
	}
}


// Stack position
.search-form {
	z-index: -1;

	&::before {
		z-index: 10;
	}

	form {
		z-index: 0;
	}

	@include breakpoint(large){
		z-index: 100;
	}
}

.lang-toggle,
.search-toggle,
.donar,
.menu-toggle,
.fundacion-link {
	z-index: 100;
}





