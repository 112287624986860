// Text general styles


// Section title
%section-title {
	display: block;
	width: 100%;
}

.section-title {
	@extend %section-title;
	margin-bottom: $global-margin;
}

.section-title--small {
	margin: 0;
	line-height: 180%;
	@extend %section-title;
	text-transform: uppercase;
}


// Button primary
.button {
	text-transform: uppercase;

	&.primary {
		color: $white;
		font-weight: bold;
	}
}


// Item
.item {
	.desc {
		@include breakpoint(768px down) {
			font-size: $small-font-size;
		}
	}
}


// General
.note {
	font-size: $text-note;
	margin: 0;
	padding: .25em 0;
	line-height: 1.25em;
}

.sans {
	font-family: $body-font-family;
}


// Text size
.text- {
	&small {
		font-size: $small-font-size;
	}
	&xsmall {
		font-size: $small-font-size * .85;
	}
}






