// Ads

.ads-column {
	text-align: center;
	// padding-top: $global-padding;
	// padding-bottom: $global-padding; 

	.ad-block {
		position: relative;
		display: inline-block;
		width: 100%;
		max-width: $ad-max-width;
		margin: 0 0 $global-margin 0;
		padding: 0;
	}

	@include breakpoint(medium only) {
		.ad-block {
			margin: 0 ($global-margin/2) $global-margin ($global-margin/4);
		}
	}

	@include breakpoint(large) {
		padding-right: 0;
		text-align: right;
	}
}