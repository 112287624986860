// Pagination


.pagination {
}

.pagination-previous,
.pagination-next {

	span,
	a {
		//display: inline;
		vertical-align: middle;
	}

	.icon {
		font-size: $icon-xsmall;
		vertical-align: middle;
	}
}
