// Donaciones


#donaciones {
	form {
		fieldset {
			display: block;
			margin: $global-padding*.5 $global-padding*-.25;

			@include breakpoint(medium) {
				margin: $global-padding*.5 $global-padding*-.5;
			}
			
			.item {
				margin: $global-padding*.25 0;
			}

			%donate-options-blocks {
				text-align: center;
				font-weight: bold;
				padding: $global-padding*.5 0 0 0;
				border: 1px solid $medium-gray;
				@include border-radius($global-radius);

				@include breakpoint(768px) {
					padding-top: $global-padding*.65;
				}

				@include breakpoint(768px) {
					padding-top: $global-padding*.65;
				}
			}

			label {
				@extend %donate-options-blocks;

				&:hover {
					background-color: $primary-color;
					border-color: $primary-color-hover;
					cursor: pointer;
				}

				@include breakpoint(768px) {
					font-size: 1rem;

					[type=radio] {
						@include translate(0, -2px);
					}
				}

				@include breakpoint(768px) {
					font-size: 1rem;

					[type=radio] {
						@include translate(0, -2px);
					}
				}
			}

			&.cantidades {
				@include grid-layout(3, ".item", $global-padding/2);

				@include breakpoint(medium) {
					@include grid-layout(3, ".item", $global-padding);
				}
			}

			&.otra-cantidad {
				@include grid-layout(1, ".item", $global-padding/2);

				@include breakpoint(medium) {
					@include grid-layout(1, ".item", $global-padding);
				}

				label {
					padding: $global-padding*.5 $global-padding 0 $global-padding;

					.input-group {
						text-align: left;
						font-weight: normal;
					}
				}

				.paypal-btn-container {
					position: relative;
					text-align: center;
					border: 0;

					.paypal-btn {
						display: block;
					}
				}
			}
		}
	}

	.fda {
		padding-top: $global-padding;

		@include breakpoint(large) {
			padding-top: rem-calc(10px);
		}
	}
}