// Icon size


.icon- {
	&xlarge {
		font-size: $icon-xlarge;
	}

	&large {
		font-size: $icon-large;
	}

	&medium {
		font-size: $icon-medium;
	}

	&small {
		font-size: $icon-small;
	}

	&xsmall {
		font-size: $icon-xsmall;
	}

	&xxsmall {
		font-size: $icon-xxsmall;
	}
}

