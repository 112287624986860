// Submenu de sección


.section-menu {
	display: block;
	list-style: none;
	margin: 0;
	padding-top: 0;
	margin-left: -$global-padding;

	li {
		a {
			@include border-radius($global-radius);
		}
	}
}