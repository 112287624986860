@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i|Roboto:400,400i,700,700i");
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #EAEAEA;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #232222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 4px; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 4px;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 102.5rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -1rem;
    margin-left: -1rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media screen and (min-width: 90em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 1rem;
  padding-left: 1rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1rem;
  padding-left: 1rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xxlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xxlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    width: 25%; }
  .xxlarge-push-3 {
    position: relative;
    left: 25%; }
  .xxlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xxlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xxlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    width: 50%; }
  .xxlarge-push-6 {
    position: relative;
    left: 50%; }
  .xxlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xxlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xxlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    width: 75%; }
  .xxlarge-push-9 {
    position: relative;
    left: 75%; }
  .xxlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xxlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xxlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxlarge-uncentered,
  .xxlarge-push-0,
  .xxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 2rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 2rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.5;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: 700;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", Georgia, Times, serif;
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #DDDDDD; }

h1 {
  font-size: 2.375rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.15rem; }

h2 {
  font-size: 1.75rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.15rem; }

h3 {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.15rem; }

h4 {
  font-size: 1.25rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.15rem; }

h5 {
  font-size: 1rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.15rem; }

h6 {
  font-size: 0.875rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.15rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 2rem; }
  h4 {
    font-size: 1.5rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #AF7F66;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #9c6b52; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 102.5rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #DDDDDD;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.5; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: 700; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #DDDDDD; }
  blockquote, blockquote p {
    line-height: 1.5;
    color: #95989A; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #95989A; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #232222;
  color: #232222;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #DDDDDD;
  background-color: #EAEAEA;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  color: #232222; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #EAEAEA;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #232222;
  border-radius: 4px; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.4;
  color: #95989A; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #95989A;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.8em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #FF7F2F;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #ff6302;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #FF7F2F;
    color: #232222; }
    .button.primary:hover, .button.primary:focus {
      background-color: #f25d00;
      color: #232222; }
  .button.secondary {
    background-color: #AF7F66;
    color: #232222; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #92644c;
      color: #232222; }
  .button.success {
    background-color: #5FC949;
    color: #232222; }
    .button.success:hover, .button.success:focus {
      background-color: #47a932;
      color: #232222; }
  .button.warning {
    background-color: #F42534;
    color: #ffffff; }
    .button.warning:hover, .button.warning:focus {
      background-color: #d60b1a;
      color: #ffffff; }
  .button.alert {
    background-color: #AF7F66;
    color: #232222; }
    .button.alert:hover, .button.alert:focus {
      background-color: #92644c;
      color: #232222; }
  .button.black {
    background-color: #232222;
    color: #ffffff; }
    .button.black:hover, .button.black:focus {
      background-color: #1c1b1b;
      color: #ffffff; }
  .button.hollow {
    border: 1px solid #FF7F2F;
    color: #FF7F2F; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #973a00;
      color: #973a00; }
    .button.hollow.primary {
      border: 1px solid #FF7F2F;
      color: #FF7F2F; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #973a00;
        color: #973a00; }
    .button.hollow.secondary {
      border: 1px solid #AF7F66;
      color: #AF7F66; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #5b3e30;
        color: #5b3e30; }
    .button.hollow.success {
      border: 1px solid #5FC949;
      color: #5FC949; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #2c6a1f;
        color: #2c6a1f; }
    .button.hollow.warning {
      border: 1px solid #F42534;
      color: #F42534; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #860710;
        color: #860710; }
    .button.hollow.alert {
      border: 1px solid #AF7F66;
      color: #AF7F66; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #5b3e30;
        color: #5b3e30; }
    .button.hollow.black {
      border: 1px solid #232222;
      color: #232222; }
      .button.hollow.black:hover, .button.hollow.black:focus {
        border-color: #121111;
        color: #121111; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #FF7F2F;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #FF7F2F;
        color: #232222; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #AF7F66;
        color: #232222; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #5FC949;
        color: #232222; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #F42534;
        color: #ffffff; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #AF7F66;
        color: #232222; }
    .button.disabled.black, .button[disabled].black {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.black, .button.disabled.black:hover, .button.disabled.black:focus, .button[disabled].black, .button[disabled].black:hover, .button[disabled].black:focus {
        background-color: #232222;
        color: #ffffff; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #FF7F2F; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #FF7F2F; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #AF7F66; }
  .button.dropdown.hollow.success::after {
    border-top-color: #5FC949; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #F42534; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #AF7F66; }
  .button.dropdown.hollow.black::after {
    border-top-color: #232222; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 2px rgba(35, 34, 34, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  color: #232222;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #95989A;
    background-color: #ffffff;
    box-shadow: 0 0 5px #DDDDDD;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #DDDDDD; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #EAEAEA;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 4px; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
  color: #232222; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #232222; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 4px 0 0 4px; }
  .input-group > :last-child > * {
    border-radius: 0 4px 4px 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #DDDDDD;
  background: #EAEAEA;
  color: #232222;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #DDDDDD; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #EAEAEA; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #232222;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28149, 152, 154%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #95989A;
    background-color: #ffffff;
    box-shadow: 0 0 5px #DDDDDD;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #EAEAEA;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #AF7F66;
  background-color: #f7f2f0; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #AF7F66; }

.is-invalid-label {
  color: #AF7F66; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #AF7F66; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: transparent;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 4px 4px 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 4px 4px; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #EAEAEA;
  border-bottom: 0;
  font-size: 1rem;
  line-height: 1;
  color: #232222; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #EAEAEA;
    border-radius: 0 0 4px 4px; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #EAEAEA; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #EAEAEA;
  border-bottom: 0;
  background-color: #ffffff;
  color: #232222; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #EAEAEA; }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(35, 34, 34, 0.25);
  border-radius: 4px;
  background-color: white;
  color: #232222; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #ffece0;
    color: #232222; }
  .callout.secondary {
    background-color: #f3ece8;
    color: #232222; }
  .callout.success {
    background-color: #e7f7e4;
    color: #232222; }
  .callout.warning {
    background-color: #fddee1;
    color: #232222; }
  .callout.alert {
    background-color: #f3ece8;
    color: #232222; }
  .callout.black {
    background-color: #dedddd;
    color: #232222; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.close-button {
  position: absolute;
  color: #95989A;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #232222; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  @media screen and (min-width: 75em) {
    .menu.xlarge-horizontal > li {
      display: table-cell; }
    .menu.xlarge-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.xlarge-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.xlarge-vertical > li {
      display: block; } }
  @media screen and (min-width: 90em) {
    .menu.xxlarge-horizontal > li {
      display: table-cell; }
    .menu.xxlarge-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.xxlarge-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.xxlarge-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 0; }
  .menu .active > a {
    background: #FF7F2F;
    color: #ffffff; }
  .menu.menu-bordered li {
    border: 1px solid 0; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #EAEAEA; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 400px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 288px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; } }

@media screen and (min-width: 75em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; } }

@media screen and (min-width: 90em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #DDDDDD;
  background: #ffffff; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(35, 34, 34, 0.5);
  color: #ffffff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #ffffff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(35, 34, 34, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 0.625rem;
    height: 0.625rem;
    margin: 0.3rem;
    border-radius: 50%;
    background-color: #232222; }
    .orbit-bullets button:hover {
      background-color: #FF7F2F; }
    .orbit-bullets button.is-active {
      background-color: #FF7F2F; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 4px;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 4px;
    color: #232222; }
    .pagination a:hover,
    .pagination button:hover {
      background: #EAEAEA; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #FF7F2F;
    color: #ffffff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #DDDDDD;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #232222; }

.tabs {
  margin: 0;
  border: 1px solid none;
  background: #ffffff;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #FF7F2F; }
  .tabs.primary > li > a {
    color: #232222; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #ff7620; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 0.7rem 1rem;
    font-size: 100%;
    line-height: 1;
    color: #232222; }
    .tabs-title > a:hover {
      background: #EAEAEA;
      color: #1e1d1d; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #EAEAEA;
      color: #AF7F66; }

.tabs-content {
  border: 1px solid none;
  border-top: 0;
  background: #ffffff;
  color: #232222;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid none;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 0; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(255, 127, 47, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

@font-face {
  font-family: 'cg';
  src: url("../fonts/cg.eot?fekmw3");
  src: url("../fonts/cg.eot?fekmw3#iefix") format("embedded-opentype"), url("../fonts/cg.ttf?fekmw3") format("truetype"), url("../fonts/cg.woff?fekmw3") format("woff"), url("../fonts/cg.svg?fekmw3#cg") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="cg-"], [class*=" cg-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cg' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.cg-icon-menu-small:before {
  content: ""; }

.cg-icon-menu:before {
  content: ""; }

.cg-icon-search:before {
  content: ""; }

.cg-icon-top-small:before {
  content: ""; }

.cg-icon-right-small:before {
  content: ""; }

.cg-icon-bottom-small:before {
  content: ""; }

.cg-icon-left-small:before {
  content: ""; }

.cg-icon-top:before {
  content: ""; }

.cg-icon-right:before {
  content: ""; }

.cg-icon-bottom:before {
  content: ""; }

.cg-icon-left:before {
  content: ""; }

.cg-icon-close:before {
  content: ""; }

.cg-icon-ddd:before {
  content: ""; }

.cg-icon-circle:before {
  content: ""; }

.cg-icon-info:before {
  content: ""; }

.cg-icon-play:before {
  content: ""; }

.cg-icon-play-small:before {
  content: ""; }

.cg-icon-facebook:before {
  content: ""; }

.cg-icon-twitter:before {
  content: ""; }

.cg-icon-yelp:before {
  content: ""; }

.cg-icon-youtube:before {
  content: ""; }

.cg-icon-google:before {
  content: ""; }

.cg-icon-instagram:before {
  content: ""; }

.cg-icon-linkedin:before {
  content: ""; }

.cg-icon-pinterest:before {
  content: ""; }

.cg-icon-wordpress:before {
  content: ""; }

.cg-icon-pdf:before {
  content: ""; }

.cg-icon-3mw:before {
  content: ""; }

.cg-icon-portal:before {
  content: ""; }

.cg-icon-institucion:before {
  content: ""; }

.cg-icon-ilustrador:before {
  content: ""; }

.cg-icon-escritor:before {
  content: ""; }

.cg-icon-editorial:before {
  content: ""; }

.cg-icon-blog:before {
  content: ""; }

.cg-icon-logo-premio:before {
  content: ""; }

.cg-icon-asi-nos-ven:before {
  content: ""; }

.cg-icon-fiesta-lectura-simple:before {
  content: ""; }

.cg-icon-fiesta-lectura:before {
  content: ""; }

.cg-icon-miau-blog:before {
  content: ""; }

.cg-icon-podcast:before {
  content: ""; }

.cg-icon-premio-cg:before {
  content: ""; }

.cg-icon-quienes-somos:before {
  content: ""; }

.cg-icon-donar:before {
  content: ""; }

html,
body {
  min-width: 20rem; }

[data-bkg],
[bkgmulti] {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.bkg-orange {
  background-color: #FF7F2F; }

.bkg-gold {
  background-color: #AF7F66; }

.bkg-red {
  background-color: #F42534; }

.bkg-white {
  background-color: #ffffff; }

.bkg-black {
  background-color: #232222; }

.transition {
  -webkit-transition: All 0.4s ease;
  -moz-transition: All 0.4s ease;
  -o-transition: All 0.4s ease;
  transition: All 0.4s ease; }

.opacity {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1; }

a:hover {
  cursor: pointer; }

a.opacity:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5; }

.scale {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1); }
  .scale:hover {
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }

.alpha {
  list-style-type: lower-alpha; }

.list-unstyled {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }
  .list-unstyled li {
    margin: 0;
    padding: 0; }

input, select, textarea {
  -webkit-appearance: none; }

input[type=checkbox] {
  -webkit-appearance: checkbox; }

input[type=radio] {
  -webkit-appearance: radio; }

* {
  outline: 0 none !important; }

a,
button,
input [type="button"],
input [type="submit"] {
  cursor: pointer;
  text-decoration: none; }

.p-0 {
  padding: 0 !important; }

.p-lr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.p-tb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-t-0 {
  padding-top: 0 !important; }

.p-r-0 {
  padding-right: 0 !important; }

.p-b-0 {
  padding-bottom: 0 !important; }

.p-l-0 {
  padding-left: 0 !important; }

.p-t-1 {
  padding-top: 1rem !important; }

.p-r-1 {
  padding-right: 1rem !important; }

.p-b-1 {
  padding-bottom: 1rem !important; }

.p-l-1 {
  padding-left: 1rem !important; }

.p-t-2 {
  padding-top: 2rem !important; }

.p-r-2 {
  padding-right: 2rem !important; }

.p-b-2 {
  padding-bottom: 2rem !important; }

.p-l-2 {
  padding-left: 2rem !important; }

.p-t-3 {
  padding-top: 3rem !important; }

.p-r-3 {
  padding-right: 3rem !important; }

.p-b-3 {
  padding-bottom: 3rem !important; }

.p-l-3 {
  padding-left: 3rem !important; }

.p-t-4 {
  padding-top: 4rem !important; }

.p-r-4 {
  padding-right: 4rem !important; }

.p-b-4 {
  padding-bottom: 4rem !important; }

.p-l-4 {
  padding-left: 4rem !important; }

.m-0 {
  margin: 0 !important; }

.m-lr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.m-tb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-t-0 {
  margin-top: 0 !important; }

.m-r-0 {
  margin-right: 0 !important; }

.m-b-0 {
  margin-bottom: 0 !important; }

.m-l-0 {
  margin-left: 0 !important; }

.m-t-1 {
  margin-top: 1rem !important; }

.m-r-1 {
  margin-right: 1rem !important; }

.m-b-1 {
  margin-bottom: 1rem !important; }

.m-l-1 {
  margin-left: 1rem !important; }

.m-t-2 {
  margin-top: 2rem !important; }

.m-r-2 {
  margin-right: 2rem !important; }

.m-b-2 {
  margin-bottom: 2rem !important; }

.m-l-2 {
  margin-left: 2rem !important; }

.m-t-3 {
  margin-top: 3rem !important; }

.m-r-3 {
  margin-right: 3rem !important; }

.m-b-3 {
  margin-bottom: 3rem !important; }

.m-l-3 {
  margin-left: 3rem !important; }

.m-t-4 {
  margin-top: 4rem !important; }

.m-r-4 {
  margin-right: 4rem !important; }

.m-b-4 {
  margin-bottom: 4rem !important; }

.m-l-4 {
  margin-left: 4rem !important; }

[data-bkg],
[data-bkg-sm],
[data-bkg-md],
[data-bkg-lg] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

@media print, screen and (min-width: 64em) {
  .padding-h-large {
    padding-left: 1rem !important;
    padding-right: 1rem !important; } }

@media screen and (min-width: 75em) {
  .padding-h-large {
    padding-left: 3rem !important;
    padding-right: 3rem !important; } }

@media screen and (min-width: 90em) {
  .padding-h-large {
    padding-left: 6rem !important;
    padding-right: 6rem !important; } }

@media print, screen and (min-width: 64em) {
  .margin-h-large {
    margin-left: 1rem !important;
    margin-right: 1rem !important; } }

@media screen and (min-width: 75em) {
  .margin-h-large {
    margin-left: 3rem !important;
    margin-right: 3rem !important; } }

@media screen and (min-width: 90em) {
  .margin-h-large {
    margin-left: 6rem !important;
    margin-right: 6rem !important; } }

@media print, screen and (min-width: 40em) {
  .padding-v-large {
    padding-top: 1rem;
    padding-bottom: 2rem; } }

@media print, screen and (min-width: 64em) {
  .padding-v-large {
    padding-bottom: 3rem; } }

.fullwidth {
  display: block;
  width: 100%;
  height: auto; }

.std {
  max-width: 46rem; }

.icon-xlarge {
  font-size: 8rem; }

.icon-large {
  font-size: 4rem; }

.icon-medium {
  font-size: 3rem; }

.icon-small {
  font-size: 2rem; }

.icon-xsmall {
  font-size: 1.5rem; }

.icon-xxsmall {
  font-size: 1.125rem; }

.section-title, .section-title--small {
  display: block;
  width: 100%; }

.section-title {
  margin-bottom: 1rem; }

.section-title--small {
  margin: 0;
  line-height: 180%;
  text-transform: uppercase; }

.button {
  text-transform: uppercase; }
  .button.primary {
    color: #ffffff;
    font-weight: bold; }

@media screen and (max-width: 48em) {
  .item .desc {
    font-size: 80%; } }

.note {
  font-size: 0.9em;
  margin: 0;
  padding: .25em 0;
  line-height: 1.25em; }

.sans {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif; }

.text-small {
  font-size: 80%; }

.text-xsmall {
  font-size: 68%; }

[class*=" b-"] {
  border: 0;
  border-style: solid; }

.b-t {
  border-top-width: 1px; }

.b-b {
  border-bottom-width: 1px; }

.b-l {
  border-left-width: 1px; }

.b-r {
  border-right-width: 1px; }

.b-white {
  border-color: #ffffff; }

.b-light-gray {
  border-color: #EAEAEA; }

.b-medium-gray {
  border-color: #DDDDDD; }

.b-dark-gray {
  border-color: #95989A; }

.b-black {
  border-color: #232222; }

@media print, screen and (min-width: 64em) {
  .detail-content .tabs {
    width: 25%;
    float: left;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-left: 0 !important; } }
  @media print, screen and (min-width: 64em) and (min-width: 40em) {
    .detail-content .tabs {
      padding-right: 1rem;
      padding-left: 1rem; } }

@media print, screen and (min-width: 64em) {
    .detail-content .tabs:last-child:not(:first-child) {
      float: right; } }

.detail-content .tabs .accordion-links {
  float: left;
  padding-top: 1rem;
  margin-top: 1rem; }
  .detail-content .tabs .accordion-links > a {
    display: block;
    padding: 0.7rem 1rem;
    font-size: 100%;
    line-height: 1;
    color: #232222; }
    .detail-content .tabs .accordion-links > a:hover {
      background: #EAEAEA;
      color: #1e1d1d; }
    .detail-content .tabs .accordion-links > a:focus, .detail-content .tabs .accordion-links > a[aria-selected='true'] {
      background: #EAEAEA;
      color: #AF7F66; }

.detail-content .tabs .accordion-info {
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px #DDDDDD solid; }

@media print, screen and (min-width: 64em) {
  .detail-content .tabs-content {
    width: 75%;
    float: left;
    padding-right: 1rem;
    padding-left: 1rem; } }
  @media print, screen and (min-width: 64em) and (min-width: 40em) {
    .detail-content .tabs-content {
      padding-right: 1rem;
      padding-left: 1rem; } }

@media print, screen and (min-width: 64em) {
    .detail-content .tabs-content:last-child:not(:first-child) {
      float: right; } }

@media print, screen and (min-width: 40em) {
  .detail-content > .accordion {
    margin-top: -1rem; } }

.detail-content > .accordion > .accordion-item > .accordion-title {
  font-weight: bold; }

.detail-content > .accordion > .accordion-item:first-child .accordion-title {
  border-top: 0; }

.detail-content .accordion .accordion-item > .accordion-title::after {
  color: #232222; }

.detail-content .accordion .accordion-links {
  padding: 1rem 0; }
  .detail-content .accordion .accordion-links a {
    display: block;
    padding: 0.25rem 1rem; }

.detail-content .accordion .accordion-info {
  padding-left: 1rem;
  padding-right: 1rem; }

.detail-content .accordion-links {
  border-top: 1px #DDDDDD solid;
  width: 100% !important; }
  .detail-content .accordion-links a {
    color: #AF7F66 !important; }

.detail-content .accordion-info .reference {
  padding-top: 0;
  padding-bottom: 0; }

.accordion {
  width: 100%; }
  .accordion .is-accordion-submenu-parent > a,
  .accordion .is-dropdown-submenu-parent > a,
  .accordion .accordion-item > .accordion-title {
    position: relative; }
    .accordion .is-accordion-submenu-parent > a::after,
    .accordion .is-dropdown-submenu-parent > a::after,
    .accordion .accordion-item > .accordion-title::after {
      font-family: 'cg';
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      font-size: 1.125rem;
      line-height: 1.125rem;
      color: #ffffff;
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -0.5625rem;
      right: 0.5rem;
      -webkit-transition: All 0.25s ease;
      -moz-transition: All 0.25s ease;
      -o-transition: All 0.25s ease;
      transition: All 0.25s ease; }
      @media print, screen and (min-width: 64em) {
        .accordion .is-accordion-submenu-parent > a::after,
        .accordion .is-dropdown-submenu-parent > a::after,
        .accordion .accordion-item > .accordion-title::after {
          color: #232222; } }
  .accordion .is-accordion-submenu-parent[aria-expanded='true'] > a::after,
  .accordion .is-dropdown-submenu-parent.is-active > a::after,
  .accordion .accordion-item.is-active > .accordion-title::after {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }

.thum .inner {
  position: relative;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  line-height: 0; }

a.thum {
  overflow: visible; }
  a.thum .inner {
    transition: box-shadow 200ms ease-out; }
    a.thum .inner:hover, a.thum .inner:focus {
      box-shadow: 0 0 6px 1px rgba(255, 127, 47, 0.5); }
    a.thum .inner image {
      box-shadow: none; }

.title p {
  line-height: 1.25;
  padding: 0; }

.page-header {
  position: relative;
  display: block;
  height: 4.5rem;
  background-color: #ffffff;
  z-index: 1000; }
  @media print, screen and (min-width: 40em) {
    .page-header {
      height: 4.125rem; } }
  @media print, screen and (min-width: 64em) {
    .page-header {
      height: 4.875rem; } }
  .page-header .logo {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    background-color: #ffffff; }
    @media print, screen and (min-width: 40em) {
      .page-header .logo {
        border: 0.625rem solid #ffffff; } }
  .page-header .header-logo {
    position: relative;
    width: 3.375rem; }
    @media print, screen and (min-width: 40em) {
      .page-header .header-logo {
        width: 6rem; } }
    @media print, screen and (min-width: 64em) {
      .page-header .header-logo {
        width: 7.5rem; } }

.page-footer {
  display: block;
  width: 100%;
  background-color: #232222; }
  .page-footer a {
    color: #FF7F2F; }
  @media print, screen and (min-width: 40em) {
    .page-footer {
      margin-top: 4rem; } }
  .page-footer .small {
    font-size: 80%; }
  .page-footer .footer-left {
    padding: 1rem;
    text-align: center; }
    @media print, screen and (min-width: 40em) {
      .page-footer .footer-left {
        text-align: left; } }
  .page-footer .footer-right {
    padding: 1rem;
    background-color: #FF7F2F; }
    .page-footer .footer-right h2 {
      color: #ffffff; }
  .page-footer .subscribe-form {
    position: relative; }
    .page-footer .subscribe-form .subscribe-box {
      margin-bottom: 0.5rem; }
    .page-footer .subscribe-form .button {
      width: 100%; }
    @media print, screen and (min-width: 40em) {
      .page-footer .subscribe-form .subscribe-box {
        padding-right: 7.25rem;
        margin-bottom: 1rem; }
      .page-footer .subscribe-form .button {
        position: absolute;
        width: 6.25rem;
        top: 0;
        right: 0; } }
  .page-footer .footer-social {
    display: block;
    list-style: none;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    z-index: 1; }
    @media screen and (max-width: 39.9375em) {
      .page-footer .footer-social {
        padding: 0;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0; } }
    .page-footer .footer-social ul {
      width: 100%;
      padding: 0;
      margin: 0; }
    @media print, screen and (min-width: 40em) {
      .page-footer .footer-social {
        position: absolute;
        top: 0;
        left: 1rem;
        padding: 0 1rem 0.75rem 1rem;
        width: 200%;
        margin-top: -4rem;
        background-color: #ffffff; } }
    @media print, screen and (min-width: 64em) {
      .page-footer .footer-social {
        width: 150%; }
        .page-footer .footer-social ul {
          padding-bottom: 1rem;
          padding-right: 450px; } }
    @media screen and (min-width: 75em) {
      .page-footer .footer-social {
        width: 133.4%; }
        .page-footer .footer-social ul {
          padding-right: 200px; } }
    .page-footer .footer-social li {
      display: inline-block;
      margin: 0 0.5rem 0 0;
      padding: 0; }
      @media screen and (max-width: 39.9375em) {
        .page-footer .footer-social li:last-child {
          margin-right: 0; } }
      @media print, screen and (min-width: 40em) {
        .page-footer .footer-social li {
          margin: 0 1rem; } }
      @media print, screen and (min-width: 64em) {
        .page-footer .footer-social li {
          margin: 0 0.5rem; } }
      @media screen and (min-width: 75em) {
        .page-footer .footer-social li {
          margin: 0 0.8rem; } }
      .page-footer .footer-social li a {
        color: #ffffff; }
        .page-footer .footer-social li a:hover {
          color: #FF7F2F; }
        .page-footer .footer-social li a span {
          font-size: 1.8rem; }
        @media print, screen and (min-width: 40em) {
          .page-footer .footer-social li a {
            color: #232222; }
            .page-footer .footer-social li a span {
              font-size: 3rem; } }
        @media print, screen and (min-width: 64em) {
          .page-footer .footer-social li a span {
            font-size: 2rem; } }
  .page-footer .footer-menu {
    list-style: none;
    margin: 0;
    padding: 0; }
    .page-footer .footer-menu li {
      display: inline-block;
      margin-right: 1.4em; }
    .page-footer .footer-menu a {
      font-weight: bold; }
  .page-footer .footer-logo {
    display: inline-block; }
    .page-footer .footer-logo img {
      width: 4.375rem; }
      @media print, screen and (min-width: 40em) {
        .page-footer .footer-logo img {
          width: 5rem; } }
    @media print, screen and (min-width: 40em) {
      .page-footer .footer-logo {
        display: block;
        float: left;
        margin: 1rem 1rem 0 0; } }
  .page-footer .footer-info {
    padding-top: 1em; }
    .page-footer .footer-info p {
      line-height: normal;
      margin: 0;
      padding: 0; }
    .page-footer .footer-info a {
      display: block;
      margin-top: 0.25rem; }
  .page-footer .copy,
  .page-footer .devel {
    color: #ffffff; }
  @media print, screen and (min-width: 40em) {
    .page-footer .copy {
      padding-top: 2.1875rem; } }
  .page-footer .devel {
    color: #ffffff;
    text-align: center;
    padding-top: 2rem; }
    @media print, screen and (min-width: 40em) {
      .page-footer .devel {
        padding-top: 3.75rem; } }
    .page-footer .devel p {
      margin: 0;
      padding: 0; }
    .page-footer .devel .devel-logo {
      display: inline-block;
      font-size: 4rem;
      color: #ffffff;
      height: 0;
      line-height: 0; }
  .page-footer .banner-gato {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .page-footer .banner-gato {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        height: 120px;
        margin-top: -116px;
        margin-right: -40px;
        z-index: 10; } }

.ads-column {
  text-align: center; }
  .ads-column .ad-block {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 18.75rem;
    margin: 0 0 1rem 0;
    padding: 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .ads-column .ad-block {
      margin: 0 0.5rem 1rem 0.25rem; } }
  @media print, screen and (min-width: 64em) {
    .ads-column {
      padding-right: 0;
      text-align: right; } }

html,
body {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0; }

body {
  height: auto;
  min-height: 100%; }

.wrapper, .wrapper-inner {
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0; }

.wrapper {
  overflow-x: hidden; }

.wrapper-inner {
  min-width: 320px; }
  @media screen and (max-width: 63.9375em) {
    .wrapper-inner {
      -webkit-transition: All 0.4s ease;
      -moz-transition: All 0.4s ease;
      -o-transition: All 0.4s ease;
      transition: All 0.4s ease; } }
  .wrapper-inner.active {
    -moz-transform: translate(-16.5rem, 0);
    -o-transform: translate(-16.5rem, 0);
    -ms-transform: translate(-16.5rem, 0);
    -webkit-transform: translate(-16.5rem, 0);
    transform: translate(-16.5rem, 0); }
    @media print, screen and (min-width: 40em) {
      .wrapper-inner.active {
        -moz-transform: translate(-20rem, 0);
        -o-transform: translate(-20rem, 0);
        -ms-transform: translate(-20rem, 0);
        -webkit-transform: translate(-20rem, 0);
        transform: translate(-20rem, 0); } }
    @media print, screen and (min-width: 64em) {
      .wrapper-inner.active {
        -moz-transform: none;
        -o-transform: none;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none; } }

main {
  background-color: #ffffff;
  padding-bottom: 2rem; }

.section-footer {
  padding-top: 1rem;
  border-top: 1px solid #DDDDDD; }
  .section-footer .button {
    width: 100%; }
    @media print, screen and (min-width: 40em) {
      .section-footer .button {
        width: auto; } }

.page-header--menu {
  float: right; }
  .page-header--menu .menu-option {
    vertical-align: middle;
    line-height: 1.5rem;
    height: 4.5rem;
    padding: 0 1rem;
    cursor: pointer; }
    @media print, screen and (min-width: 40em) {
      .page-header--menu .menu-option {
        min-width: 4.125rem;
        height: 4.125rem;
        text-align: center; } }
    @media print, screen and (min-width: 64em) {
      .page-header--menu .menu-option {
        min-width: auto;
        height: 4.875rem; } }
    .page-header--menu .menu-option a,
    .page-header--menu .menu-option a span {
      display: inline-block;
      height: 4.5rem;
      line-height: 4.5rem;
      color: #232222; }
      @media print, screen and (min-width: 40em) {
        .page-header--menu .menu-option a,
        .page-header--menu .menu-option a span {
          height: 4.125rem;
          line-height: 4.125rem; } }
      @media print, screen and (min-width: 64em) {
        .page-header--menu .menu-option a,
        .page-header--menu .menu-option a span {
          height: 4.875rem;
          line-height: 4.875rem; } }
  .page-header--menu .icon {
    display: inline-block;
    height: 1.5rem;
    font-size: 1.5rem;
    vertical-align: middle;
    line-height: 4.5rem; }
    @media print, screen and (min-width: 40em) {
      .page-header--menu .icon {
        line-height: 4.125rem; } }
    @media print, screen and (min-width: 64em) {
      .page-header--menu .icon {
        line-height: 4.875rem; } }

[data-multilang="false"] .lang-toggle {
  display: none; }

[data-multilang="true"] .lang-toggle {
  float: left;
  border-right: 1px solid #EAEAEA; }

.lang-toggle .icon {
  margin-top: -.15em; }

.lang-toggle span {
  display: none !important; }
  @media print, screen and (min-width: 40em) {
    .lang-toggle span {
      display: inline-block !important; } }

.fundacion-link {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .fundacion-link {
      display: block;
      float: left; } }
  .fundacion-link a {
    text-align: right; }
    @media print, screen and (min-width: 64em) {
      .fundacion-link a {
        padding-top: 2.4375rem;
        margin-top: -1.73333em; } }
    .fundacion-link a h4 {
      color: #FF7F2F;
      font-size: 1.7em;
      line-height: 1.275em; }
    .fundacion-link a:hover h4 {
      color: #ff6505; }

.search-toggle {
  float: left;
  border-left: 1px solid #EAEAEA;
  border-right: 1px solid #EAEAEA; }
  .search-toggle .search-open {
    display: block; }
  .search-toggle .search-close {
    display: none; }
  .search-toggle.active .search-open {
    display: none; }
  .search-toggle.active .search-close {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .search-toggle {
      border-right: 0; } }
  @media print, screen and (min-width: 64em) {
    .search-toggle {
      display: none; } }

.search-form {
  position: absolute;
  display: block;
  width: 100%;
  margin: 0;
  top: 0;
  right: 0; }
  @media print, screen and (min-width: 40em) {
    .search-form {
      width: 28.5rem; } }
  @media print, screen and (min-width: 64em) {
    .search-form {
      position: relative;
      float: left;
      top: 0;
      left: auto;
      background-color: transparent;
      width: auto;
      padding-left: 0;
      border-left: 1px solid #EAEAEA; } }
  .search-form::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 4.5rem;
    background-color: #ffffff; }
    @media print, screen and (min-width: 40em) {
      .search-form::before {
        height: 4.125rem; } }
    @media print, screen and (min-width: 64em) {
      .search-form::before {
        display: none; } }
  .search-form form {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: #232222;
    -webkit-transition: All 0.4s ease;
    -moz-transition: All 0.4s ease;
    -o-transition: All 0.4s ease;
    transition: All 0.4s ease; }
    .search-form form::before {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border: inset 0.5625rem;
      content: '';
      border-bottom-width: 0;
      border-top-style: solid;
      border-color: #ffffff transparent transparent;
      top: 0;
      right: 4.75rem;
      margin: 0;
      padding: 0;
      z-index: 100; }
    .search-form form label {
      display: none; }
    .search-form form .search-box {
      width: 100%;
      height: 100%;
      padding-right: 3rem;
      margin: 0; }
    .search-form form .search-btn {
      position: absolute;
      right: 1.5rem;
      top: 0;
      z-index: 1; }
      .search-form form .search-btn:hover {
        color: #FF7F2F; }
      .search-form form .search-btn .icon {
        margin-top: 0; }
        @media print, screen and (min-width: 64em) {
          .search-form form .search-btn .icon {
            margin-top: 0; } }
    @media print, screen and (min-width: 40em) {
      .search-form form {
        margin-top: -0.75rem; }
        .search-form form::before {
          right: 13.6875rem; } }
    @media print, screen and (min-width: 64em) {
      .search-form form {
        margin-top: 0;
        position: relative;
        padding: 0;
        background-color: transparent;
        width: 7em; }
        .search-form form::before {
          display: none; }
        .search-form form .search-box {
          border: 0;
          box-shadow: none;
          padding: 1.625rem 0;
          font-size: 1.25em; }
          .search-form form .search-box::placeholder {
            color: "green";
            font-size: 1.25em; }
          .search-form form .search-box::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: "green";
            font-size: 1.25em; }
          .search-form form .search-box::-moz-placeholder {
            /* Firefox 19+ */
            color: "green";
            font-size: 1.25em; }
        .search-form form .search-btn {
          right: 0; } }
  .search-form.active form {
    margin-top: 4.5rem; }
    @media print, screen and (min-width: 40em) {
      .search-form.active form {
        margin-top: 4.125rem; } }
    @media print, screen and (min-width: 64em) {
      .search-form.active form {
        margin-top: 0;
        width: 24.3rem; } }

.donar {
  display: none; }
  @media print, screen and (min-width: 40em) {
    .donar {
      display: block;
      float: left;
      background-color: #FF7F2F;
      letter-spacing: -.025em;
      padding: 0 !important; }
      .donar a {
        color: #ffffff !important;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.25em; } }
  @media print, screen and (min-width: 40em) {
    .donar {
      padding: 0 2rem !important; }
      .donar:hover {
        background-color: #ff6505; } }

.menu-toggle {
  float: left; }
  .menu-toggle .menu-open {
    display: block; }
    .wrapper-inner.active .menu-toggle .menu-open {
      display: none; }
  .menu-toggle .menu-close {
    display: none; }
    .wrapper-inner.active .menu-toggle .menu-close {
      display: block; }
  @media print, screen and (min-width: 64em) {
    .menu-toggle {
      display: none; } }

.search-form {
  z-index: -1; }
  .search-form::before {
    z-index: 10; }
  .search-form form {
    z-index: 0; }
  @media print, screen and (min-width: 64em) {
    .search-form {
      z-index: 100; } }

.lang-toggle,
.search-toggle,
.donar,
.menu-toggle,
.fundacion-link {
  z-index: 100; }

.main-menu--outer {
  position: absolute;
  top: 0;
  right: -16.5rem;
  padding: 0;
  height: 100%;
  background-color: #232222;
  width: 16.5rem; }
  @media print, screen and (min-width: 40em) {
    .main-menu--outer {
      right: -20rem;
      width: 20rem; } }
  @media print, screen and (min-width: 64em) {
    .main-menu--outer {
      position: relative;
      right: 0;
      margin-top: 0;
      background-color: #ffffff;
      width: 100%; } }

.main-menu {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  background-color: #232222; }
  .main-menu::before {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: inset 0.5625rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #ffffff;
    top: 1.6875rem;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 100; }
  @media print, screen and (min-width: 40em) {
    .main-menu::before {
      top: 1.5rem; } }
  @media print, screen and (min-width: 64em) {
    .main-menu {
      border-bottom: 1px solid #DDDDDD;
      text-align: center;
      padding: .5em 0;
      background-color: transparent; }
      .main-menu::before {
        display: none; } }
  .main-menu > .menu {
    display: block;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0; }
    @media print, screen and (min-width: 64em) {
      .main-menu > .menu {
        display: inline-block;
        width: auto; }
        .main-menu > .menu ul {
          width: auto; } }
  .main-menu li {
    border-top: 1px solid #3d3b3b; }
    @media print, screen and (min-width: 64em) {
      .main-menu li {
        padding: .25em;
        border: 0; } }
    .main-menu li a {
      color: #ffffff;
      padding-top: .9em;
      padding-bottom: 1em;
      font-weight: bold;
      line-height: 125%; }
      .main-menu li a:hover {
        background-color: #FF7F2F; }
      @media print, screen and (min-width: 64em) {
        .main-menu li a {
          color: #232222;
          padding-top: .8em;
          padding-bottom: .8em;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          white-space: nowrap; } }
    .main-menu li[aria-expanded="true"] {
      background-color: #302f2f; }
    .main-menu li li {
      border-bottom: 0;
      border-top: 1px solid #3d3b3b; }
      @media print, screen and (min-width: 64em) {
        .main-menu li li {
          border: 0; } }
      .main-menu li li a {
        padding-left: 2rem;
        font-weight: normal; }
        @media print, screen and (min-width: 64em) {
          .main-menu li li a {
            padding: 0.8em 1rem; } }
  .main-menu .is-accordion-submenu-parent > a,
  .main-menu .is-dropdown-submenu-parent > a,
  .main-menu .accordion-item > .accordion-title {
    position: relative; }
    .main-menu .is-accordion-submenu-parent > a::after,
    .main-menu .is-dropdown-submenu-parent > a::after,
    .main-menu .accordion-item > .accordion-title::after {
      font-family: 'cg';
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      font-size: 1.125rem;
      line-height: 1.125rem;
      color: #ffffff;
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -0.5625rem;
      right: 0.5rem;
      -webkit-transition: All 0.25s ease;
      -moz-transition: All 0.25s ease;
      -o-transition: All 0.25s ease;
      transition: All 0.25s ease; }
      @media print, screen and (min-width: 64em) {
        .main-menu .is-accordion-submenu-parent > a::after,
        .main-menu .is-dropdown-submenu-parent > a::after,
        .main-menu .accordion-item > .accordion-title::after {
          color: #232222; } }
  .main-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after,
  .main-menu .is-dropdown-submenu-parent.is-active > a::after,
  .main-menu .accordion-item.is-active > .accordion-title::after {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  .main-menu .is-dropdown-submenu-parent > a {
    padding-right: 1.9125rem; }
  .main-menu .menu-extras {
    padding: 1rem;
    color: #ffffff;
    font-size: 80%; }
  .main-menu .note {
    font-weight: normal; }

#noticias .page-banner--small, #detail-noticias .page-banner--small {
  background-color: #AF7F66; }

#articulos .page-banner--small, #detail-articulos .page-banner--small,
#entrevistas .page-banner--small, #detail-entrevistas .page-banner--small,
#bitacora .page-banner--small, #detail-bitacora .page-banner--small {
  background-color: #EF6666; }

#poesia .page-banner--small, #detail-poesia .page-banner--small,
#narrativa .page-banner--small, #detail-narrativa .page-banner--small,
#teatro .page-banner--small, #detail-teatro .page-banner--small {
  background-color: #5798c0; }

#ojo-avizor .page-banner--small, #detail-ojo-avizor .page-banner--small {
  background-color: #62a672; }

#ilustradores .page-banner--small {
  background-color: #b67079; }

#enlaces .page-banner--small {
  background-color: #997ccf; }

#contacto .page-banner--small {
  background-color: #6dc4b5; }

#premio .page-banner--small, #detail-premio .page-banner--small,
#donaciones .page-banner--small,
#al .page-banner--small {
  background-color: #FF7F2F; }

#fundacion .page-banner--small {
  background-color: #dd7a7a; }

#asinosven .page-banner--small {
  background-color: #31c08f; }

#buscar .page-banner--small {
  background-color: #ead068; }

#podcast .page-banner--small,
#detail-podcast .page-banner--small {
  background-color: #8fc031; }

#detail-evento .page-banner--small {
  background-color: #FF7F2F; }

@media print, screen and (min-width: 40em) {
  .page-banner--small .inner.white *, .page-banner--large .orbit-slide .orbit-caption .inner.white *,
  .page-banner--large .orbit-slide .slide-caption .inner.white *,
  .page-banner--large .slide .orbit-caption .inner.white *,
  .page-banner--large .slide .slide-caption .inner.white * {
    color: #ffffff; }
  .page-banner--small .inner.black *, .page-banner--large .orbit-slide .orbit-caption .inner.black *,
  .page-banner--large .orbit-slide .slide-caption .inner.black *,
  .page-banner--large .slide .orbit-caption .inner.black *,
  .page-banner--large .slide .slide-caption .inner.black * {
    color: #232222; } }

@media screen and (min-width: 48em) {
  .page-banner--small .inner.white .bar .subtitle, .page-banner--large .orbit-slide .orbit-caption .inner.white .bar .subtitle,
  .page-banner--large .orbit-slide .slide-caption .inner.white .bar .subtitle,
  .page-banner--large .slide .orbit-caption .inner.white .bar .subtitle,
  .page-banner--large .slide .slide-caption .inner.white .bar .subtitle {
    border-left-color: #ffffff !important; }
  .page-banner--small .inner.black .bar .subtitle, .page-banner--large .orbit-slide .orbit-caption .inner.black .bar .subtitle,
  .page-banner--large .orbit-slide .slide-caption .inner.black .bar .subtitle,
  .page-banner--large .slide .orbit-caption .inner.black .bar .subtitle,
  .page-banner--large .slide .slide-caption .inner.black .bar .subtitle {
    border-left-color: #232222 !important; } }

@media print, screen and (min-width: 40em) {
  .page-banner--small .title {
    font-size: 3rem;
    padding: 0 6rem;
    text-shadow: 0 0 10px #232222; }
  .page-banner--small .desc {
    padding: 0 6rem;
    text-shadow: 0 0 10px #232222; } }

@media print, screen and (min-width: 64em) {
  .page-banner--small .title {
    font-size: 3.85rem; }
  .page-banner--small .desc {
    font-size: 120%;
    padding: 0 12rem; } }

@media screen and (min-width: 75em) {
  .page-banner--small .title {
    font-size: 4.25rem;
    padding: 0 12rem; }
  .page-banner--small .desc {
    font-size: 150%;
    padding: 0 20%; } }

@media print, screen and (min-width: 40em) {
  .page-banner--large .orbit-slide .orbit-caption .title,
  .page-banner--large .orbit-slide .slide-caption .title,
  .page-banner--large .slide .orbit-caption .title,
  .page-banner--large .slide .slide-caption .title {
    font-size: 3rem;
    padding: 0 6rem;
    text-shadow: 0 0 10px #232222; }
  .page-banner--large .orbit-slide .orbit-caption .desc,
  .page-banner--large .orbit-slide .slide-caption .desc,
  .page-banner--large .slide .orbit-caption .desc,
  .page-banner--large .slide .slide-caption .desc {
    font-size: 150%;
    line-height: 1.25;
    padding: 0 6rem;
    padding-top: .5em;
    text-shadow: 0 0 10px #232222; } }

@media print, screen and (min-width: 64em) {
  .page-banner--large .orbit-slide .orbit-caption .title,
  .page-banner--large .orbit-slide .slide-caption .title,
  .page-banner--large .slide .orbit-caption .title,
  .page-banner--large .slide .slide-caption .title {
    font-size: 3.85rem; }
  .page-banner--large .orbit-slide .orbit-caption .desc,
  .page-banner--large .orbit-slide .slide-caption .desc,
  .page-banner--large .slide .orbit-caption .desc,
  .page-banner--large .slide .slide-caption .desc {
    font-size: 175%;
    padding: 0 12rem;
    padding-top: .5em; } }

@media screen and (min-width: 75em) {
  .page-banner--large .orbit-slide .orbit-caption .title,
  .page-banner--large .orbit-slide .slide-caption .title,
  .page-banner--large .slide .orbit-caption .title,
  .page-banner--large .slide .slide-caption .title {
    font-size: 5rem;
    padding: 0 12rem; }
  .page-banner--large .orbit-slide .orbit-caption .desc,
  .page-banner--large .orbit-slide .slide-caption .desc,
  .page-banner--large .slide .orbit-caption .desc,
  .page-banner--large .slide .slide-caption .desc {
    font-size: 220%;
    padding: 0 20%;
    padding-top: .5em; } }

.page-banner--large .orbit-slide .orbit-caption .button,
.page-banner--large .orbit-slide .slide-caption .button,
.page-banner--large .slide .orbit-caption .button,
.page-banner--large .slide .slide-caption .button {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  margin: 0; }
  @media print, screen and (min-width: 40em) {
    .page-banner--large .orbit-slide .orbit-caption .button,
    .page-banner--large .orbit-slide .slide-caption .button,
    .page-banner--large .slide .orbit-caption .button,
    .page-banner--large .slide .slide-caption .button {
      display: inline-block;
      width: auto;
      margin: 0 0 1rem 0;
      font-size: 110%;
      padding: .8em 1.5em; } }
  @media print, screen and (min-width: 64em) {
    .page-banner--large .orbit-slide .orbit-caption .button,
    .page-banner--large .orbit-slide .slide-caption .button,
    .page-banner--large .slide .orbit-caption .button,
    .page-banner--large .slide .slide-caption .button {
      font-size: 130%; } }
  @media screen and (min-width: 75em) {
    .page-banner--large .orbit-slide .orbit-caption .button,
    .page-banner--large .orbit-slide .slide-caption .button,
    .page-banner--large .slide .orbit-caption .button,
    .page-banner--large .slide .slide-caption .button {
      font-size: 150%; } }

.page-banner--small {
  position: relative;
  display: block;
  height: 12.5rem; }
  @media print, screen and (min-width: 40em) {
    .page-banner--small {
      height: 15.625rem; } }
  .page-banner--small:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding-top: 0;
    background-image: url(../img/texturaBkg.png);
    background-repeat: repeat; }
  .page-banner--small > .inner {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    background-color: transparent; }
    @media print, screen and (min-width: 40em) {
      .page-banner--small > .inner {
        width: 70%; } }
    .page-banner--small > .inner .page-title h1,
    .page-banner--small > .inner .page-title .title,
    .page-banner--small > .inner .page-title .subtitle {
      display: block;
      padding: 0 1rem; }
      @media screen and (min-width: 48em) {
        .page-banner--small > .inner .page-title h1,
        .page-banner--small > .inner .page-title .title,
        .page-banner--small > .inner .page-title .subtitle {
          padding: 0.5rem 1rem; } }
    .page-banner--small > .inner .page-title .subtitle {
      text-transform: uppercase;
      font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif; }
    @media screen and (min-width: 48em) {
      .page-banner--small > .inner .page-title.bar h1,
      .page-banner--small > .inner .page-title.bar .title,
      .page-banner--small > .inner .page-title.bar .subtitle {
        display: inline-block; }
      .page-banner--small > .inner .page-title.bar .subtitle {
        font-size: 2.1875rem;
        border-left: 1px solid #232222; } }
    .page-banner--small > .inner .page-desc {
      display: inline-block;
      padding: 0;
      margin-bottom: 0;
      color: #ffffff;
      font-weight: normal;
      line-height: 1.25em; }
      @media screen and (min-width: 48em) {
        .page-banner--small > .inner .page-desc {
          font-size: 1.25rem;
          max-width: 700px; } }
      @media screen and (min-width: 75em) {
        .page-banner--small > .inner .page-desc {
          font-size: 1.25rem; } }
  .page-banner--small .banner-gato {
    display: none; }
    @media print, screen and (min-width: 40em) {
      .page-banner--small .banner-gato {
        position: absolute;
        display: block;
        bottom: 0;
        right: .5em;
        height: 50%;
        z-index: 1; } }
    @media print, screen and (min-width: 64em) {
      .page-banner--small .banner-gato {
        height: 60%; } }
    @media screen and (min-width: 75em) {
      .page-banner--small .banner-gato {
        height: 65%; } }

.orbit .orbit-previous,
.orbit .orbit-next {
  font-size: 3rem; }

.page-banner--large > .inner, .page-banner--large .orbit-slide .orbit-image,
.page-banner--large .orbit-slide .slide-image,
.page-banner--large .slide .orbit-image,
.page-banner--large .slide .slide-image {
  height: 15.625rem; }
  @media print, screen and (min-width: 40em) {
    .page-banner--large > .inner, .page-banner--large .orbit-slide .orbit-image,
    .page-banner--large .orbit-slide .slide-image,
    .page-banner--large .slide .orbit-image,
    .page-banner--large .slide .slide-image {
      height: 28.125rem; } }
  @media print, screen and (min-width: 64em) {
    .page-banner--large > .inner, .page-banner--large .orbit-slide .orbit-image,
    .page-banner--large .orbit-slide .slide-image,
    .page-banner--large .slide .orbit-image,
    .page-banner--large .slide .slide-image {
      height: 34.375rem; } }
  @media screen and (min-width: 75em) {
    .page-banner--large > .inner, .page-banner--large .orbit-slide .orbit-image,
    .page-banner--large .orbit-slide .slide-image,
    .page-banner--large .slide .orbit-image,
    .page-banner--large .slide .slide-image {
      height: 40.625rem; } }

.page-banner--large {
  background-color: #ffffff; }
  .page-banner--large.orbit {
    padding-bottom: 2em; }
  .page-banner--large .orbit-bullets {
    position: absolute;
    width: 100%;
    bottom: 0; }
    @media print, screen and (min-width: 40em) {
      .page-banner--large .orbit-bullets {
        margin-bottom: .2rem; } }
  .page-banner--large .orbit-previous,
  .page-banner--large .orbit-next {
    top: 0;
    -moz-transform: translate(0, 5.3125rem);
    -o-transform: translate(0, 5.3125rem);
    -ms-transform: translate(0, 5.3125rem);
    -webkit-transform: translate(0, 5.3125rem);
    transform: translate(0, 5.3125rem); }
    @media print, screen and (min-width: 40em) {
      .page-banner--large .orbit-previous,
      .page-banner--large .orbit-next {
        top: 50%;
        -moz-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%); } }
  .page-banner--large .orbit-slide .orbit-image,
  .page-banner--large .orbit-slide .slide-image,
  .page-banner--large .slide .orbit-image,
  .page-banner--large .slide .slide-image {
    display: block;
    width: 100%;
    background-color: #EAEAEA; }
  .page-banner--large .orbit-slide .orbit-caption,
  .page-banner--large .orbit-slide .slide-caption,
  .page-banner--large .slide .orbit-caption,
  .page-banner--large .slide .slide-caption {
    position: relative;
    background-color: transparent;
    color: #232222; }
    @media print, screen and (min-width: 40em) {
      .page-banner--large .orbit-slide .orbit-caption,
      .page-banner--large .orbit-slide .slide-caption,
      .page-banner--large .slide .orbit-caption,
      .page-banner--large .slide .slide-caption {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center; }
        .page-banner--large .orbit-slide .orbit-caption.bottom,
        .page-banner--large .orbit-slide .slide-caption.bottom,
        .page-banner--large .slide .orbit-caption.bottom,
        .page-banner--large .slide .slide-caption.bottom {
          top: auto;
          bottom: 5rem;
          transform: translate(-50%, -100%); }
        .page-banner--large .orbit-slide .orbit-caption .inner,
        .page-banner--large .orbit-slide .slide-caption .inner,
        .page-banner--large .slide .orbit-caption .inner,
        .page-banner--large .slide .slide-caption .inner {
          width: 100%;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); } }

.main-sections2 {
  background-color: #ffffff;
  border-top: 1px solid #DDDDDD; }
  @media print, screen and (min-width: 40em) {
    .main-sections2 {
      border-top: 0; } }
  .main-sections2 .items {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #DDDDDD;
    color: #232222;
    text-align: center;
    width: 33.33333%;
    float: left;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    @media print, screen and (min-width: 40em) {
      .main-sections2 .items {
        padding-right: 1rem;
        padding-left: 1rem; } }
    .main-sections2 .items:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 51.25em) {
      .main-sections2 .items {
        width: 16.66667%;
        float: left;
        padding-right: 1rem;
        padding-left: 1rem; } }
  @media screen and (min-width: 51.25em) and (min-width: 40em) {
    .main-sections2 .items {
      padding-right: 1rem;
      padding-left: 1rem; } }
    @media screen and (min-width: 51.25em) {
        .main-sections2 .items:last-child:not(:first-child) {
          float: right; } }
    .main-sections2 .items:hover {
      background-color: #EAEAEA; }
    .main-sections2 .items .title {
      margin: 0;
      padding: 0;
      font-size: 80%;
      line-height: 1.25;
      font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif; }
      @media screen and (min-width: 28.125em) {
        .main-sections2 .items .title {
          font-family: "Playfair Display", Georgia, Times, serif;
          font-size: 100%;
          display: block; } }
    .main-sections2 .items .note {
      display: none; }
      @media screen and (min-width: 51.25em) {
        .main-sections2 .items .note {
          display: block; } }
    .main-sections2 .items .thumb {
      display: block;
      width: 4rem;
      display: inline-block;
      margin-bottom: 0.5rem; }
      .main-sections2 .items .thumb .inner {
        display: block;
        height: 0;
        overflow: hidden;
        padding: 0 0 100% 0;
        background-size: contain; }
      @media screen and (min-width: 28.125em) {
        .main-sections2 .items .thumb {
          width: 5.2rem; } }
      @media print, screen and (min-width: 40em) {
        .main-sections2 .items .thumb {
          margin-top: 0.5rem;
          margin-bottom: 1rem; } }
      @media screen and (min-width: 75em) {
        .main-sections2 .items .thumb {
          width: 6rem; } }
    .main-sections2 .items .icon {
      font-size: 4rem;
      padding: .20rem;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: #DDDDDD; }
      @media screen and (min-width: 28.125em) {
        .main-sections2 .items .icon {
          font-size: 5.2rem; } }
      @media screen and (min-width: 75em) {
        .main-sections2 .items .icon {
          font-size: 6rem; } }
    .main-sections2 .items::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 0;
      height: 100%;
      border-right: 1px solid #DDDDDD; }
    .main-sections2 .items:first-child::before {
      display: none; }
    .main-sections2 .items:nth-child(4n+4)::before {
      display: none; }
    @media screen and (min-width: 51.25em) {
      .main-sections2 .items:nth-child(4n+4)::before {
        display: block; } }
  .main-sections2 .items-fundacion .icon {
    background-color: #dd7a7a; }
  .main-sections2 .items-fiesta-letura .icon {
    background-color: #dd7ac5; }
  .main-sections2 .items-premio .icon {
    background-color: #FF7F2F; }
  .main-sections2 .items-blog .icon {
    background-color: #d3a451; }
  .main-sections2 .items-ilustradores .icon {
    background-color: #31c08f; }
  .main-sections2 .items-podcast .icon {
    background-color: #8fc031; }

.carrusel {
  background-color: #ffffff;
  display: block;
  overflow: hidden; }
  @media screen and (max-width: 63.9375em) {
    .carrusel {
      padding-left: 0;
      padding-right: 0; } }
  .carrusel .item {
    position: relative;
    float: left;
    width: 100%;
    padding: 1rem 1rem 0 1rem; }
    @media screen and (min-width: 25em) {
      .carrusel .item {
        width: 50%; }
        .carrusel .item.slick-active:before {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 200%;
          width: 1px;
          background-color: #DDDDDD; } }
    @media print, screen and (min-width: 40em) {
      .carrusel .item {
        width: 33.333%; } }
    @media print, screen and (min-width: 64em) {
      .carrusel .item {
        width: 25%; } }
    @media screen and (min-width: 90em) {
      .carrusel .item {
        width: 20%; } }
    .carrusel .item .thumb .inner {
      display: block;
      height: 0;
      overflow: hidden;
      padding: 0 0 100% 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center bottom;
      margin-bottom: 0.625rem; }
      @media screen and (min-width: 25em) {
        .carrusel .item .thumb .inner {
          background-position: left bottom; } }
    .carrusel .item .item-data {
      text-align: center; }
      @media screen and (min-width: 25em) {
        .carrusel .item .item-data {
          text-align: left; } }

.featured-news--inner {
  background-color: #EAEAEA;
  padding-top: 0;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  float: left; }
  @media print, screen and (min-width: 40em) {
    .featured-news--inner {
      padding-top: 1rem; } }

.featured-news .section-title {
  background-color: #DDDDDD;
  padding: 2rem 1rem 0.7rem 1rem;
  margin: 0; }
  @media print, screen and (min-width: 40em) {
    .featured-news .section-title {
      padding: 0 1rem 0.33333rem 1rem;
      background-color: transparent;
      border: 0; } }

.featured-news .main-news {
  background-color: #DDDDDD; }
  @media print, screen and (min-width: 40em) {
    .featured-news .main-news {
      background-color: transparent; } }

.featured-news .other-news {
  list-style: none;
  margin: 0;
  padding: 0; }
  .featured-news .other-news li {
    border-top: 1px solid #DDDDDD;
    padding-top: 0.8rem; }
    .featured-news .other-news li:first-child {
      border: 0; }
      @media print, screen and (min-width: 40em) {
        .featured-news .other-news li:first-child {
          padding-top: 0; } }

.featured-news .button {
  width: 100%; }
  @media print, screen and (min-width: 40em) {
    .featured-news .button {
      width: auto; } }

.list {
  list-style: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0; }
  .list .item {
    display: block;
    width: 100%; }
    .list .item::before, .list .item::after {
      display: table;
      content: ' '; }
    .list .item::after {
      clear: both; }
    .list .item > .inner {
      padding: 1rem 0;
      border-top: 1px solid #DDDDDD; }
    .list .item .thumbnail {
      display: block;
      height: 5.625rem; }
      .list .item .thumbnail img {
        height: 100%; }
      @media screen and (min-width: 48em) {
        .list .item .thumbnail {
          height: 7.5rem; } }
    .list .item .thumb {
      display: block;
      width: 5.625rem; }
      @media screen and (min-width: 48em) {
        .list .item .thumb {
          width: 7.5rem; } }
      .list .item .thumb .inner {
        display: block;
        height: 0;
        overflow: hidden;
        padding: 0 0 100% 0;
        background-size: contain; }
    .list .item .item-data {
      min-height: 5.625rem; }
      @media screen and (min-width: 48em) {
        .list .item .item-data {
          min-height: 7.5rem; } }
      .list .item .item-data .category {
        display: block;
        font-size: 80%;
        text-transform: uppercase;
        margin: -6px 0 0 0; }
      .list .item .item-data .desc {
        display: block;
        margin: 0;
        padding: 0; }
    .list .item.item-thum--left .thumbnail,
    .list .item.item-thum--left .thumb {
      float: left;
      margin-right: 1rem; }
      .list .item.item-thum--left .thumbnail .inner,
      .list .item.item-thum--left .thumb .inner {
        background-position: top left; }
    .list .item.item-thum--right .thumbnail,
    .list .item.item-thum--right .thumb {
      float: right;
      margin-left: 1rem; }
      .list .item.item-thum--right .thumbnail .inner,
      .list .item.item-thum--right .thumb .inner {
        background-position: top right; }

.list-group .item:first-child > .inner {
  border-top: 0; }

.list-column-2 .item, .list-column-3 .item {
  display: inline-block;
  padding: 0 0.875rem;
  vertical-align: top; }

@media print, screen and (min-width: 40em) {
  .list-column-2 .item {
    width: 49.5%; } }

@media print, screen and (min-width: 40em) {
  .list-column-3 .item {
    width: 49.5%; } }

@media print, screen and (min-width: 64em) {
  .list-column-3 .item {
    width: 33%; } }

.list-detalle .item .inner {
  max-width: 102.5rem;
  margin-right: auto;
  margin-left: auto; }
  .list-detalle .item .inner::before, .list-detalle .item .inner::after {
    display: table;
    content: ' '; }
  .list-detalle .item .inner::after {
    clear: both; }

.list-detalle .item .item-img {
  width: 100%;
  float: left;
  padding-right: 1rem;
  padding-left: 1rem;
  width: auto;
  padding-left: 0 !important;
  padding-right: 0 !important; }
  @media print, screen and (min-width: 40em) {
    .list-detalle .item .item-img {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .list-detalle .item .item-img:last-child:not(:first-child) {
    float: right; }
  .list-detalle .item .item-img .thumbnail,
  .list-detalle .item .item-img img {
    width: auto;
    height: auto;
    max-width: auto;
    max-height: auto;
    margin: 0; }
  .list-detalle .item .item-img .thumbnail {
    margin: 0 0 0.5rem 0; }

.list-detalle .item .item-data {
  width: 100%;
  float: left;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-left: 0 !important;
  padding-right: 0 !important; }
  @media print, screen and (min-width: 40em) {
    .list-detalle .item .item-data {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .list-detalle .item .item-data:last-child:not(:first-child) {
    float: right; }

@media print, screen and (min-width: 40em) {
  .list-detalle .item .item-img {
    width: 37.5%;
    float: left;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-right: 0.5rem !important; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .list-detalle .item .item-img {
      padding-right: 1rem;
      padding-left: 1rem; } }

@media print, screen and (min-width: 40em) {
    .list-detalle .item .item-img:last-child:not(:first-child) {
      float: right; }
    .list-detalle .item .item-img .thumbnail {
      margin: 0; }
  .list-detalle .item .item-data {
    width: 62.5%;
    float: left;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-left: 0.5rem !important; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .list-detalle .item .item-data {
      padding-right: 1rem;
      padding-left: 1rem; } }

@media print, screen and (min-width: 40em) {
    .list-detalle .item .item-data:last-child:not(:first-child) {
      float: right; } }

.reference {
  display: block;
  width: 100%;
  position: relative;
  margin: 1.3rem 0 0 0; }
  .reference a {
    display: inline;
    position: relative;
    text-transform: uppercase;
    color: #232222; }
    .reference a::before, .reference a::after {
      display: table;
      content: ' '; }
    .reference a::after {
      clear: both; }
    .reference a span {
      vertical-align: middle; }
    .reference a .icon {
      color: #FF7F2F;
      font-size: 1.5rem;
      vertical-align: middle;
      display: inline-block;
      margin: 0.25rem 0 0 0.375rem; }
      .reference a .icon .pointer {
        position: absolute;
        display: block;
        z-index: 10; }
        .reference a .icon .pointer::before {
          display: block;
          width: 0;
          height: 0;
          border: inset 0.5625rem;
          content: '';
          border-bottom-width: 0;
          border-top-style: solid;
          border-color: #FF7F2F transparent transparent;
          margin: -2.3125rem 0 0 0.1875rem; }
    .reference a:hover .icon {
      color: #ff6505; }
  .item .reference,
  .article-data .reference {
    margin: 0; }
    .item .reference a,
    .article-data .reference a {
      text-transform: none; }
  .item .reference a {
    color: #95989A; }
  .article-data .reference {
    display: inline-block; }
  #ilustradores .reference {
    padding-top: 0; }
  .reference.active {
    z-index: 999; }
    .reference.active a {
      z-index: 1001; }
    .reference.active::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      margin: -1rem 0 0 0;
      padding: 10px 0;
      width: 100%;
      height: 100%; }

.accordion-info .reference {
  margin-top: 0; }

.reference-dropdown {
  position: absolute;
  display: block;
  margin: 0;
  z-index: 1000;
  width: 100%;
  height: 1px;
  height: 0;
  overflow: visible;
  min-width: 288px;
  max-width: 400px;
  padding: 0 1rem; }
  .reference-dropdown.vfit {
    left: auto;
    right: 0; }
  @media screen and (min-width: 27em) {
    .reference-dropdown {
      min-width: 400px;
      padding-left: 0; } }
  .reference-dropdown .inner {
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    bottom: 0.5rem;
    min-height: 6.25rem;
    height: auto;
    padding: 0.625rem 1rem 1rem 1rem;
    background-color: #ffffff;
    border: 1px solid #DDDDDD;
    border-bottom: 0.375rem solid #FF7F2F;
    font-size: 1rem;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -moz-box-shadow: 0 0 1.3rem rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 0 1.3rem rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 1.3rem rgba(0, 0, 0, 0.25); }
  .reference-dropdown .title,
  .reference-dropdown .desc {
    line-height: 1.5;
    padding: 0;
    margin: 0; }
  .reference-dropdown .title {
    font-weight: bold;
    color: #232222; }
  .reference-dropdown .desc {
    font-size: 80% !important;
    text-align: left !important;
    color: #232222; }
    .reference-dropdown .desc::before, .reference-dropdown .desc::after {
      display: table;
      content: ' '; }
    .reference-dropdown .desc::after {
      clear: both; }
    .reference-dropdown .desc div, .reference-dropdown .desc span, .reference-dropdown .desc em, .reference-dropdown .desc ul, .reference-dropdown .desc ol, .reference-dropdown .desc li, .reference-dropdown .desc p, .reference-dropdown .desc b, .reference-dropdown .desc strong, .reference-dropdown .desc i, .reference-dropdown .desc a {
      font-size: 1em !important;
      text-align: left !important;
      margin: 0 !important;
      padding: 0 !important;
      line-height: 1.5 !important; }
  .reference-dropdown img {
    float: right;
    max-width: 6.25rem;
    max-height: 5rem;
    margin: 0.375rem 0 0.5rem 0.5rem; }

.data .reference {
  display: inline-block;
  width: auto; }

.data .reference-dropdown.vfit {
  left: 0; }

.index {
  list-style: none;
  margin: 0;
  padding: 0 0 2rem 0;
  overflow: visible; }
  .index::before, .index::after {
    display: table;
    content: ' '; }
  .index::after {
    clear: both; }
  .index .item {
    float: left;
    margin: 0 0.0625rem 0.0625rem 0;
    padding: 0; }
    .index .item .button {
      margin: 0;
      text-align: center;
      padding: .9em;
      background-color: #EAEAEA;
      color: #232222;
      font-size: 1em; }
      .index .item .button:hover {
        background-color: #FF7F2F; }
      .index .item .button[disabled]:hover {
        background-color: #EAEAEA; }
      .index .item .button.selected {
        color: #ffffff;
        background-color: #232222; }
      .index .item .button .inner {
        display: block;
        width: 1em;
        display: block;
        height: 0;
        overflow: hidden;
        padding: 0 0 100% 0; }

.pagination-previous span,
.pagination-previous a,
.pagination-next span,
.pagination-next a {
  vertical-align: middle; }

.pagination-previous .icon,
.pagination-next .icon {
  font-size: 1.5rem;
  vertical-align: middle; }

.section-menu {
  display: block;
  list-style: none;
  margin: 0;
  padding-top: 0;
  margin-left: -1rem; }
  .section-menu li a {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; }

.article-row-section {
  max-width: 102.5rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: left; }

.article-row-section-header {
  padding: 1.5rem 0;
  margin: 0;
  line-height: 1; }

.article-row {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #EAEAEA;
  padding: 1.5rem 0; }
  @media print, screen and (min-width: 40em) {
    .article-row {
      flex-direction: row; } }

.article-row-img img {
  width: 100%; }
  @media print, screen and (min-width: 40em) {
    .article-row-img img {
      max-width: none;
      width: 12.5rem; } }

.article-row-content {
  padding: 1.5rem 0 0;
  color: #232222; }
  @media print, screen and (min-width: 40em) {
    .article-row-content {
      padding: 0 0 0 1.5rem; } }

.article-row-content-header {
  font-size: 1.5rem; }

.article-row-content-description {
  padding-top: 1rem; }

.article-row-content-author,
.article-row-content-time,
.article-row-content-position {
  font-size: 0.875rem;
  margin-bottom: 0;
  color: #95989A; }

.article-row-content-position {
  text-transform: uppercase; }

.article-row-reversed .article-row-content {
  order: 2;
  padding: 0 1.5rem 0 0; }

.article-row-reversed .article-row-img {
  order: 1;
  padding: 0 0 1.5rem 0; }

@media print, screen and (min-width: 40em) {
  .article-row-reversed .article-row-content {
    order: 1; }
  .article-row-reversed .article-row-img {
    order: 2; } }

.image-gallery .thum,
.image-gallery .thumbnail {
  width: 100%; }
  .image-gallery .thum .inner,
  .image-gallery .thumbnail .inner {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0 0 100% 0; }

.video-gallery .thum,
.video-gallery .thumbnail {
  width: 100%; }
  .video-gallery .thum .inner,
  .video-gallery .thumbnail .inner {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0 0 75% 0;
    text-align: center; }
    .video-gallery .thum .inner::before,
    .video-gallery .thumbnail .inner::before {
      position: absolute;
      top: 50%;
      left: 50%;
      font-family: 'cg';
      src: url("../fonts/cg.eot?fekmw3");
      src: url("../fonts/cg.eot?fekmw3#iefix") format("embedded-opentype"), url("../fonts/cg.ttf?fekmw3") format("truetype"), url("../fonts/cg.woff?fekmw3") format("woff"), url("../fonts/cg.svg?fekmw3#cg") format("svg");
      font-weight: normal;
      font-style: normal;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      content: "";
      font-size: 2rem;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
      background-color: #ffffff;
      line-height: 2rem;
      margin: -1.5rem 0 0 -1.5rem; }

.video-gallery a .desc,
.video-gallery a:hover .desc {
  color: #232222; }

.video-gallery .reference {
  padding-top: 0;
  padding-bottom: 0; }

#video {
  position: relative;
  display: block; }
  #video video {
    width: auto;
    height: auto;
    max-width: 100%; }
  #video .mfp-bottom-bar {
    margin-top: 1.5625rem;
    text-align: center;
    padding: 0 !important; }
    #video .mfp-bottom-bar * {
      text-align: center;
      padding: 0 !important; }

.video {
  margin-bottom: .6rem; }

.g-recaptcha {
  -moz-transform-origin: left;
  -o-transform-origin: left;
  -ms-transform-origin: left;
  -webkit-transform-origin: left;
  transform-origin: left;
  -moz-transform: scale(0.954);
  -o-transform: scale(0.954);
  -ms-transform: scale(0.954);
  -webkit-transform: scale(0.954);
  transform: scale(0.954); }
  @media screen and (min-width: 25em) {
    .g-recaptcha {
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1); } }

#detail-premio .addthis_sharing_toolbox,
#detail-evento .addthis_sharing_toolbox {
  margin-bottom: 2rem; }

.detail .article-data {
  background-color: #EAEAEA; }
  @media print, screen and (min-width: 40em) {
    .detail .article-data {
      background-color: transparent; } }
  .detail .article-data .thumbnail img {
    padding-top: 1rem; }
    @media print, screen and (min-width: 40em) {
      .detail .article-data .thumbnail img {
        padding-top: 2rem; } }
  .detail .article-data ul {
    list-style: none;
    display: block;
    margin: 0 0 1rem 0;
    padding-top: 0; }
    .detail .article-data ul li {
      display: block; }
      .detail .article-data ul li:last-child {
        border: 0; }
      .detail .article-data ul li .data {
        vertical-align: middle; }
      .detail .article-data ul li label {
        display: inline-block;
        font-size: 100%;
        vertical-align: middle; }

.detail .extras {
  margin: 1rem 0;
  padding-top: 1rem;
  border-top: 1px solid #DDDDDD; }
  @media print, screen and (min-width: 40em) {
    .detail .extras {
      margin: 2rem 0; } }

.detail .article-detail {
  margin-top: 1rem;
  margin-bottom: 2rem; }
  @media print, screen and (min-width: 40em) {
    .detail .article-detail {
      padding-top: 0; } }
  .detail .article-detail .icon.pdf {
    color: #F42534;
    font-size: 1.5rem; }

#fundacion .junta .article-row-section {
  margin-top: 2rem; }

#fundacion .folletos .folletos-lista {
  margin-top: 2rem; }
  #fundacion .folletos .folletos-lista .desc {
    color: #232222; }

#asinosven .reference {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0; }

#ilustradores .reference {
  margin-top: 0;
  margin-bottom: .3rem;
  padding-top: 0;
  padding-bottom: 0; }

#enlaces .accordion-title {
  position: relative;
  padding-left: 3.5rem; }
  #enlaces .accordion-title .icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    padding: 1.25rem 1rem;
    margin: -0.5rem 0 0 0; }

#contacto .mapa {
  position: relative;
  margin-top: 0;
  margin-bottom: 2rem;
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0 0 100% 0; }
  #contacto .mapa iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0; }
  @media screen and (min-width: 30em) {
    #contacto .mapa {
      display: block;
      height: 0;
      overflow: hidden;
      padding: 0 0 66.6666% 0; } }
  @media print, screen and (min-width: 40em) {
    #contacto .mapa {
      margin-top: -1rem;
      display: block;
      height: 0;
      overflow: hidden;
      padding: 0 0 40% 0; } }
  @media print, screen and (min-width: 64em) {
    #contacto .mapa {
      margin-top: 0;
      margin-bottom: 3rem; } }

#contacto form {
  display: block;
  margin-bottom: 2rem; }

#donaciones form fieldset {
  display: block;
  margin: 0.5rem -0.25rem; }
  @media print, screen and (min-width: 40em) {
    #donaciones form fieldset {
      margin: 0.5rem -0.5rem; } }
  #donaciones form fieldset .item {
    margin: 0.25rem 0; }
  #donaciones form fieldset label {
    text-align: center;
    font-weight: bold;
    padding: 0.5rem 0 0 0;
    border: 1px solid #DDDDDD;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; }
    @media screen and (min-width: 48em) {
      #donaciones form fieldset label {
        padding-top: 0.65rem; } }
    @media screen and (min-width: 48em) {
      #donaciones form fieldset label {
        padding-top: 0.65rem; } }
  #donaciones form fieldset label:hover {
    background-color: #FF7F2F;
    border-color: #ff6505;
    cursor: pointer; }
  @media screen and (min-width: 48em) {
    #donaciones form fieldset label {
      font-size: 1rem; }
      #donaciones form fieldset label [type=radio] {
        -moz-transform: translate(0, -2px);
        -o-transform: translate(0, -2px);
        -ms-transform: translate(0, -2px);
        -webkit-transform: translate(0, -2px);
        transform: translate(0, -2px); } }
  @media screen and (min-width: 48em) {
    #donaciones form fieldset label {
      font-size: 1rem; }
      #donaciones form fieldset label [type=radio] {
        -moz-transform: translate(0, -2px);
        -o-transform: translate(0, -2px);
        -ms-transform: translate(0, -2px);
        -webkit-transform: translate(0, -2px);
        transform: translate(0, -2px); } }
  #donaciones form fieldset.cantidades > .item {
    float: left;
    width: 33.33333%;
    padding-right: 0.25rem;
    padding-left: 0.25rem; }
    #donaciones form fieldset.cantidades > .item:nth-of-type(1n) {
      clear: none; }
    #donaciones form fieldset.cantidades > .item:nth-of-type(3n+1) {
      clear: both; }
    #donaciones form fieldset.cantidades > .item:last-child {
      float: left; }
  @media print, screen and (min-width: 40em) {
    #donaciones form fieldset.cantidades > .item {
      float: left;
      width: 33.33333%;
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
      #donaciones form fieldset.cantidades > .item:nth-of-type(1n) {
        clear: none; }
      #donaciones form fieldset.cantidades > .item:nth-of-type(3n+1) {
        clear: both; }
      #donaciones form fieldset.cantidades > .item:last-child {
        float: left; } }
  #donaciones form fieldset.otra-cantidad > .item {
    float: left;
    width: 100%;
    padding-right: 0.25rem;
    padding-left: 0.25rem; }
    #donaciones form fieldset.otra-cantidad > .item:nth-of-type(1n) {
      clear: none; }
    #donaciones form fieldset.otra-cantidad > .item:nth-of-type(1n+1) {
      clear: both; }
    #donaciones form fieldset.otra-cantidad > .item:last-child {
      float: left; }
  @media print, screen and (min-width: 40em) {
    #donaciones form fieldset.otra-cantidad > .item {
      float: left;
      width: 100%;
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
      #donaciones form fieldset.otra-cantidad > .item:nth-of-type(1n) {
        clear: none; }
      #donaciones form fieldset.otra-cantidad > .item:nth-of-type(1n+1) {
        clear: both; }
      #donaciones form fieldset.otra-cantidad > .item:last-child {
        float: left; } }
  #donaciones form fieldset.otra-cantidad label {
    padding: 0.5rem 1rem 0 1rem; }
    #donaciones form fieldset.otra-cantidad label .input-group {
      text-align: left;
      font-weight: normal; }
  #donaciones form fieldset.otra-cantidad .paypal-btn-container {
    position: relative;
    text-align: center;
    border: 0; }
    #donaciones form fieldset.otra-cantidad .paypal-btn-container .paypal-btn {
      display: block; }

#donaciones .fda {
  padding-top: 1rem; }
  @media print, screen and (min-width: 64em) {
    #donaciones .fda {
      padding-top: 0.625rem; } }

#buscar .criterio-busqueda {
  margin-top: 1rem;
  margin-bottom: 1rem; }
  #buscar .criterio-busqueda .total-resultados {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    color: #95989A; }

#buscar .buscar-categoria {
  margin-top: 1rem;
  margin-bottom: 0;
  text-transform: uppercase; }

#soundcloudPlayer {
  padding-top: 1rem;
  padding-bottom: 1rem; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
@media screen and (min-width: 25em) {
  .slick-list {
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD; }
    .slick-list:before {
      content: " ";
      position: absolute;
      width: 1rem;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff;
      z-index: 100; } }

.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "cg";
  src: url("../fonts/cg.eot");
  src: url("../fonts/cg.eot?#iefix") format("embedded-opentype"), url("../fonts/cg.woff") format("woff"), url("../fonts/cg.ttf") format("truetype"), url("../fonts/cg.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 3rem;
  width: 3rem;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  z-index: 101; }
  @media screen and (min-width: 75em) {
    .slick-prev,
    .slick-next {
      width: 3rem; } }
  @media screen and (min-width: 90em) {
    .slick-prev,
    .slick-next {
      width: 6rem; } }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      color: #FF7F2F;
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "cg";
    font-size: 3rem;
    line-height: 1;
    color: #232222;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 0; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 0; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: 0; }
  [dir="rtl"] .slick-next {
    left: 0;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0;
  padding-bottom: 2.5rem; }

.slick-dots {
  position: absolute;
  bottom: 0.625rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  left: 0; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1;
          color: #FF7F2F; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 10px;
        height: 10px;
        font-family: "cg";
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        color: #232222;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #FF7F2F;
      opacity: 1; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9012;
  overflow: hidden;
  position: fixed;
  background: #ffffff;
  opacity: 0.95; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9013;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 1.875rem;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 9015; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #232222;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 9014; }
  .mfp-preloader a {
    color: #232222; }
    .mfp-preloader a:hover {
      color: #232222; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 9016;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 1;
  padding: 0 0 18px 10px;
  color: #232222;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #232222; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #232222;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #232222;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 1;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }

.mfp-arrow-left:before,
.mfp-arrow-right:before {
  font-family: 'cg';
  src: url("../fonts/cg.eot?fekmw3");
  src: url("../fonts/cg.eot?fekmw3#iefix") format("embedded-opentype"), url("../fonts/cg.ttf?fekmw3") format("truetype"), url("../fonts/cg.woff?fekmw3") format("woff"), url("../fonts/cg.svg?fekmw3#cg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-size: 3.125rem;
  width: 5.625rem;
  height: 6.875rem;
  color: #95989A; }

.mfp-arrow-left:hover:before,
.mfp-arrow-right:hover:before {
  color: #232222; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:before {
    content: ""; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:before {
    content: ""; }

.mfp-iframe-holder {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 56.25rem; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.2);
    background: #232222; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 1rem 0 1rem;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 1rem;
    bottom: 1rem;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.2);
    background: #ffffff; }
  .mfp-figure small {
    color: #232222;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: 3rem;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #232222;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
			 * Remove all paddings around the image on small screen
			 */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }

.mfp-bg {
  min-width: 20rem; }

.mfp-content {
  min-width: 18.125rem;
  text-align: center; }

.mfp-bottom-bar {
  margin-top: 0.9375rem; }
  .mfp-bottom-bar .mfp-title {
    text-align: left; }
    .mfp-bottom-bar .mfp-title .title {
      font-weight: bold; }
    .mfp-bottom-bar .mfp-title small {
      font-weight: normal; }

.mfp-close {
  position: fixed;
  display: block;
  top: 0;
  left: 50%;
  margin: 0 0 0 -1.5625rem;
  padding: 0;
  width: 3.125rem !important;
  height: 3.125rem; }
  @media print, screen and (min-width: 64em) {
    .mfp-close {
      left: auto;
      right: 0 !important; } }
  .mfp-close:before {
    left: 0;
    padding: 0.625rem;
    font-size: 1.875rem;
    color: #ffffff;
    background-color: #FF7F2F;
    width: 100%;
    height: 100%; }
  .mfp-close:hover:before {
    background-color: #9c6b52; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: default; }

.mfp-zoom {
  cursor: default; }
