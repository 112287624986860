// Main Sections


.main-sections2 {
	background-color: $white;
	border-top: 1px solid $medium-gray;

	@include breakpoint(medium) {
		border-top: 0;
	}
	
	.items {
		position: relative;
		overflow: hidden;
		border-bottom: 1px solid $medium-gray;
		color: $black;
		text-align: center;
		@include grid-col(1 of 3);
		padding-top: $global-padding;
		padding-bottom: $global-padding;
		padding-left: $global-padding/2;
		padding-right: $global-padding/2; 

		@include breakpoint(820px) {
			@include grid-col(1 of 6);
		}

		&:hover {
			background-color: $light-gray;
		}

		.title {
			margin: 0;
			padding: 0;
			font-size: $small-font-size;
			line-height: 1.25;
			font-family: $body-font-family;

			@include breakpoint(450) {
				font-family: $heading-font-family;
				font-size: 100%;
				display: block;
			}
		}

		.note {
			display: none;

			@include breakpoint(820) {
				display: block;
			}
		}

		.thumb {
			display: block;
			// width: 100%;
			width: $icon-large;
			display: inline-block;
			//margin-right: $global-margin;
			margin-bottom: $global-margin/2;

			.inner {
				@include ratio1-1;
				background-size: contain;

				@include breakpoint(450) {
					// @include ratio3-2;
				}
			}

			@include breakpoint(450) {
				width: $icon-large * 1.3;
			}

			@include breakpoint(medium) {
				margin-top: $global-margin/2;
				margin-bottom: $global-margin;
			}

			@include breakpoint(1200) {
				width: $icon-large * 1.5;
			}
		}

		.icon {
			font-size: $icon-large;
			padding: .20rem;

			@include border-radius(50%);
			background-color: $medium-gray;

			@include breakpoint(450) {
				font-size: $icon-large * 1.3;
			}

			@include breakpoint(1200) {
				font-size: $icon-large * 1.5;
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 0;
			height: 100%;
			border-right: 1px solid $medium-gray;
		}

		&:first-child {
			&::before {
				display: none;
			}
		}

		&:nth-child(4n+4) {
			&::before {
				display: none;
			}
		}

		@include breakpoint(820) {
			&:nth-child(4n+4) {
				&::before {
					display: block;
				}
			}
		}
	}

	.items-fundacion .icon{
		background-color: map-get($banner-bkg-color, quienesomos);
	}

	.items-fiesta-letura .icon{
		background-color: map-get($banner-bkg-color, fiesta);
	}

	.items-premio .icon{
		background-color: $primary-color;
	}

	.items-blog .icon{
		background-color: darken(map-get($banner-bkg-color, blog), 10%);
	}

	.items-ilustradores .icon{
		background-color: darken(map-get($banner-bkg-color, asinosven), 20%);
	}

	.items-podcast .icon{
		background-color: darken(map-get($banner-bkg-color, podcast), 20%);
	}
}