$icomoon-font-path: "../fonts" !default;

$cg-icon-menu-small: "\e900";
$cg-icon-menu: "\e901";
$cg-icon-search: "\e902";
$cg-icon-top-small: "\e903";
$cg-icon-right-small: "\e904";
$cg-icon-bottom-small: "\e905";
$cg-icon-left-small: "\e906";
$cg-icon-top: "\e907";
$cg-icon-right: "\e908";
$cg-icon-bottom: "\e909";
$cg-icon-left: "\e90a";
$cg-icon-close: "\e90b";
$cg-icon-ddd: "\e90c";
$cg-icon-circle: "\e90d";
$cg-icon-info: "\e90e";
$cg-icon-play: "\e90f";
$cg-icon-play-small: "\e910";
$cg-icon-facebook: "\e911";
$cg-icon-twitter: "\e912";
$cg-icon-yelp: "\e913";
$cg-icon-youtube: "\e914";
$cg-icon-google: "\e915";
$cg-icon-instagram: "\e916";
$cg-icon-linkedin: "\e917";
$cg-icon-pinterest: "\e918";
$cg-icon-wordpress: "\e919";
$cg-icon-pdf: "\e91a";
$cg-icon-3mw: "\e91b";
$cg-icon-portal: "\e91d";
$cg-icon-institucion: "\e91e";
$cg-icon-ilustrador: "\e91f";
$cg-icon-escritor: "\e920";
$cg-icon-editorial: "\e921";
$cg-icon-blog: "\e922";
$cg-icon-logo-premio: "\e91c";
$cg-icon-asi-nos-ven: "\e923";
$cg-icon-fiesta-lectura-simple: "\e924";
$cg-icon-fiesta-lectura: "\e925";
$cg-icon-miau-blog: "\e926";
$cg-icon-podcast: "\e927";
$cg-icon-premio-cg: "\e928";
$cg-icon-quienes-somos: "\e929";
$cg-icon-donar: "\e92a";
