// Image Gallery

.video-gallery {
	.thum,
	.thumbnail {
		width: 100%;

		.inner {
			@include ratio4-3;
			text-align: center;

			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				@include cg-font-face;
				@include border-radius(50%);
				content: $cg-icon-play;
				font-size: $play-icon-size;
				width: $play-icon-size + $play-icon-size/2;
				height: $play-icon-size + $play-icon-size/2;
				padding: $global-padding/2;
				background-color: $white;
				line-height: $play-icon-size;
				margin: (($play-icon-size + $play-icon-size/2) / -2) 0 0 (($play-icon-size + $play-icon-size/2) / -2);
			}
		}
	}

	a,
	a:hover {
		.desc {
			color: $black;
		}
	}

	.reference {
		padding-top: 0;
		padding-bottom: 0;
	}
}

#video {
	position: relative;
	display: block;

	video {
		width: auto;
		height: auto;
		max-width: 100%;
		//box-shadow: $mfp-shadow;
	}

	.mfp-bottom-bar {
		margin-top: rem-calc(25);
		text-align: center;
		padding: 0 !important;

		* {
			text-align: center;
			padding: 0 !important;
		}
	}
}


.video {
	margin-bottom: .6rem;
}