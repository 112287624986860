// Page Header


.page-header {
	position: relative;
	display: block;
	height: map-get($header-height, small);
	background-color: $white;
	z-index: 1000;

	@include breakpoint(medium) {
		height: map-get($header-height, medium);
	}

	@include breakpoint(large) {
		height: map-get($header-height, large);
	}

	.logo {
		position: absolute;
		top: rem-calc(8);
		left: $global-padding;
		background-color: $white;

		@include breakpoint(medium) {
			border: rem-calc(10) solid $white;
		}
	}

	.header-logo {
		position: relative;
		width: $logo-small;

		@include breakpoint(medium) {
			width: $logo-medium;
		}

		@include breakpoint(large) {
			width: $logo-large;
		}
	}
}