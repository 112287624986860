// VARIABLES



//-- Page size
$min-page-width: rem-calc(320px);
$std-width: rem-calc(768px) - ($global-margin*2);


//-- Logo sizes
$logo-large: rem-calc(120px);
$logo-medium: rem-calc(96px);
$logo-small: rem-calc(54px);


//-- Page Header
$header-height: (
	small: rem-calc(72),
	medium: ($logo-medium/2) + rem-calc(18px),
	large: ($logo-large/2) + rem-calc(18px),
);


//-- Page Banner
$banner-height: (
	xsmall: rem-calc(200px),
	small: rem-calc(250px),
	medium: rem-calc(450px),
	large: rem-calc(550px),
	xlarge: rem-calc(650px),
);

$header-breackpoint: rem-calc(map-get($breakpoints, medium)) + $logo-medium + rem-calc($global-padding * 2);

$main-menu-width: (
	small: rem-calc(264px),
	medium: rem-calc(320px),
);


// Texts
$buscar-link-font-size: 1.25em;
$donar-link-font-size: 1.25em;
$fundacion-link-font-size: 1.7em;
$text-note: .9em;


// Search form
$search-form-width: (
	small: 100%,
	medium: 28.5rem,
	large: 24.3rem,
);


// Play icon size
$play-icon-size: $icon-small;


// References
$drop-down-border-bottom-width: rem-calc(6px);


// Banners - Color por página
$banner-bkg-color: (
	secondary: get-color(secondary),
	primary: get-color(primary),
	eventos: get-color(primary),
	leeryreleer: #EF6666,
	ficciones: #5798c0,
	ojoavizor: #62a672,
	ilustradores: #b67079,
	enlaces: #997ccf,
	contacto: #6dc4b5,
	buscar: #ead068,
	podcast: #bbdd7a,
	asinosven: #7addbb,
	blog: #ddb97a,
	fiesta: #dd7ac5,
	quienesomos: #dd7a7a,
);


// Ads
$ad-max-width: rem-calc(300px);




