// Image Gallery

.image-gallery {
	.thum,
	.thumbnail {
		width: 100%;

		.inner {
			@include ratio1-1;
		}
	}
}