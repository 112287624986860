// Featured News


.featured-news {

	&--inner {
		background-color: $light-gray;
		padding-top: 0;
		padding-bottom: $global-padding;
		margin-bottom: $global-margin * 2;
		float: left;

		@include breakpoint(medium) {
			padding-top: $global-padding;
		}
	}

	.section-title {
		background-color: $medium-gray;
		padding: $global-padding*2 $global-padding $global-padding*.7 $global-padding;
		margin: 0;

		@include breakpoint(medium) {
			padding: 0 $global-padding $global-padding/3 $global-padding;
			background-color: transparent;
			border: 0;
		}
	}

	.main-news {
		background-color: $medium-gray;
		
		@include breakpoint(medium) {
			background-color: transparent;
		}
	}

	.other-news {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			border-top: 1px solid $medium-gray;
			padding-top: $global-padding * .8;

			&:first-child {
				border: 0;

				@include breakpoint(medium) {
					padding-top: 0;
				}
			}
		}
	}

	.button {
		width: 100%;

		@include breakpoint(medium) {
			width: auto;
		}
	}
}