// Thum


.thum {
	.inner {
		position: relative;
		width: 100%;
		@include thumbnail;
	}
}

a.thum {
	overflow: visible;

	.inner {
		@include thumbnail-link;
	}
}
