// FOOTER


.page-footer {
	display: block;
	width: 100%;
	background-color: $black;

	a {
		color: get-color(primary);
	}
	
	@include breakpoint(medium) {
		margin-top: rem-calc(($global-padding*2) + $icon-small);
	}

	.small {
		font-size: $small-font-size;
	}

	.footer-left {
		padding: $global-padding;
		text-align: center;

		@include breakpoint(medium) {
			text-align: left;
		}
	}

	.footer-right {
		padding: $global-padding;
		background-color: get-color(primary);

		h2 {
			color: $white;
		}
	}


	// Subscribe form
	.subscribe-form {
		position: relative;

		.subscribe-box {
			margin-bottom: $global-margin/2;
		}

		.button {
			width: 100%;
		}

		@include breakpoint(medium) {
			.subscribe-box {
				padding-right: rem-calc(100px) + $global-padding;
				margin-bottom: $global-margin;
			}

			.button {
				position: absolute;
				width: rem-calc(100px);
				top: 0;
				right: 0;
			}
		}
	}


	// Social Menu
	.footer-social {
		display: block;
		list-style: none;
		width: 100%;
		text-align: center;
		margin-bottom: $global-margin;
		z-index: 1;

		@include breakpoint(small down) {
			padding: 0;
			margin-top: 0;
			margin-right: 0;
			margin-left: 0;
		}

		ul {
			width: 100%;
			padding: 0;
			margin: 0;
		}

		@include breakpoint(medium) {
			position: absolute;
			top: 0;
			left: $global-padding;
			padding: 0 $global-padding rem-calc(12px) $global-padding;
			width: 200%;
			margin-top: ($icon-medium + $global-margin) * -1;
			background-color: $white;
		}

		@include breakpoint(large) {
			width: 150%;

			ul {
				padding-bottom: $global-padding;
				padding-right: 450px;
			}
		}

		@include breakpoint(xlarge) {
			width: 133.4%;

			ul {
				padding-right: 200px;
			}
		}

		li {
			display: inline-block;
			margin: 0 $global-margin/2 0 0;
			padding: 0;

			@include breakpoint(small down) {
				&:last-child {
					margin-right: 0;
				}
			}

			@include breakpoint(medium) {
				margin: 0 $global-margin;
			}

			@include breakpoint(large) {
				margin: 0 $global-margin*.5;
			}

			@include breakpoint(xlarge) {
				margin: 0 $global-margin*.8;
			}

			a {
				color: $white;

				&:hover {
					color: get-color(primary);
				}

				span {
					font-size: $icon-small*.9;
				}

				@include breakpoint(medium) {
					color: $black;

					span {
						font-size: $icon-medium;
					}
				}

				@include breakpoint(large) {
					span {
						font-size: $icon-small;
					}
				}
			}
		}
	}


	// Footer menu
	.footer-menu {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			margin-right: 1.4em;
		}

		a {
			font-weight: bold;
		}
	}


	// Footer Logo
	.footer-logo {
		display: inline-block;

		img {
			width: rem-calc(70px);

			@include breakpoint(medium) {
				width: rem-calc(80px);
			}
		}

		@include breakpoint(medium) {
			display: block;
			float: left;
			margin: $global-margin $global-margin 0 0;
		}
	}


	// Footer-info
	.footer-info {
		padding-top: 1em;

		p {
			line-height: normal;
			margin: 0;
			padding: 0;
		}

		a {
			display: block;
			margin-top: rem-calc(4px);
		}
	}


	// Copyright & Development
	.copy,
	.devel {
		color: $white;
	}

	.copy {
		@include breakpoint(medium) {
			padding-top: rem-calc(35px);
		}
	}

	.devel {
		color: $white;
		text-align: center;
		padding-top: $global-padding*2;

		@include breakpoint(medium) {
			padding-top: rem-calc(60px);
		}

		p {
			margin: 0;
			padding: 0;
		}

		.devel-logo {
			display: inline-block;
			font-size: $icon-large;
			color: $white;
			height: 0;
			line-height: 0;
		}
	}

	.banner-gato {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			height: 120px;
			margin-top: -116px;
			margin-right: -40px;
			z-index: 10;
		}
	}
}


























