// GENERAL STYLES



// body
html,
body {
	min-width: $min-page-width;
}


// Bkg images
[data-bkg],
[bkgmulti] {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}


// Bkg color
.bkg- {
	&orange {
		background-color: get-color(primary); //map-get($foundation-palette, primary);
	}

	&gold {
		background-color: get-color(secondary); //map-get($foundation-palette, secondary);
	}

	&red {
		background-color: get-color(warning); //map-get($foundation-palette, warning);
	}

	&white {
		background-color: $white;
	}

	&black {
		background-color: $black;
	}
}


// Transformaciones
.transition { @include transition(0.4s); }

.opacity 	{ @include opacity(1); }

a {
	&:hover { cursor: pointer; }

	&.opacity {
		&:hover { @include opacity(.5); }
	}
}

.scale {
	@include scale(1);

	&:hover {
		@include scale(1.2);
	}
}


// List 
.alpha {
	list-style-type: lower-alpha; 
}

.list-unstyled {
	list-style: none;
	list-style-image: none;
	margin: 0;
	padding: 0;

	li {
		margin: 0;
		padding: 0;
	}
}


// Eliminar apariencia definida por el navegador en formularios
input, select, textarea { -webkit-appearance: none; }
input[type=checkbox] { -webkit-appearance: checkbox; }
input[type=radio] { -webkit-appearance: radio; }


// Links
* {
	outline: 0 none !important;
}

a,
button,
input [type="button"],
input [type="submit"] {
	cursor: pointer;
	text-decoration: none;
}


// Padding = 0
.p- {
	&0 { padding: 0 !important; }
	&lr-0 { 
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	&tb-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	&t-0 { padding-top: 0 !important; }
	&r-0 { padding-right: 0 !important; }
	&b-0 { padding-bottom: 0 !important; }
	&l-0 { padding-left: 0 !important; }
	&t-1 { padding-top: $global-padding !important; }
	&r-1 { padding-right: $global-padding !important; }
	&b-1 { padding-bottom: $global-padding !important; }
	&l-1 { padding-left: $global-padding !important; }
	&t-2 { padding-top: $global-padding*2 !important; }
	&r-2 { padding-right: $global-padding*2 !important; }
	&b-2 { padding-bottom: $global-padding*2 !important; }
	&l-2 { padding-left: $global-padding*2 !important; }
	&t-3 { padding-top: $global-padding*3 !important; }
	&r-3 { padding-right: $global-padding*3 !important; }
	&b-3 { padding-bottom: $global-padding*3 !important; }
	&l-3 { padding-left: $global-padding*3 !important; }
	&t-4 { padding-top: $global-padding*4 !important; }
	&r-4 { padding-right: $global-padding*4 !important; }
	&b-4 { padding-bottom: $global-padding*4 !important; }
	&l-4 { padding-left: $global-padding*4 !important; }
}

// Margin = 0
.m- {
	&0 { margin: 0 !important; }
	&lr-0 { 
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	&tb-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	&t-0 { margin-top: 0 !important; }
	&r-0 { margin-right: 0 !important; }
	&b-0 { margin-bottom: 0 !important; }
	&l-0 { margin-left: 0 !important; }
	&t-1 { margin-top: $global-margin !important; }
	&r-1 { margin-right: $global-margin !important; }
	&b-1 { margin-bottom: $global-margin !important; }
	&l-1 { margin-left: $global-margin !important; }
	&t-2 { margin-top: $global-margin*2 !important; }
	&r-2 { margin-right: $global-margin*2 !important; }
	&b-2 { margin-bottom: $global-margin*2 !important; }
	&l-2 { margin-left: $global-margin*2 !important; }
	&t-3 { margin-top: $global-margin*3 !important; }
	&r-3 { margin-right: $global-margin*3 !important; }
	&b-3 { margin-bottom: $global-margin*3 !important; }
	&l-3 { margin-left: $global-margin*3 !important; }
	&t-4 { margin-top: $global-margin*4 !important; }
	&r-4 { margin-right: $global-margin*4 !important; }
	&b-4 { margin-bottom: $global-margin*4 !important; }
	&l-4 { margin-left: $global-margin*4 !important; }
}



// Background images (data-bkg)
[data-bkg],
[data-bkg-sm],
[data-bkg-md],
[data-bkg-lg] {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}



// Large screens left and right paddings or margins
.padding-h-large {
	@include breakpoint(large) {
		padding-left: $global-padding !important;
		padding-right: $global-padding !important;
	}

	@include breakpoint(xlarge) {
		padding-left: $global-padding * 3 !important;
		padding-right: $global-padding * 3 !important;
	}

	@include breakpoint(xxlarge) {
		padding-left: $global-padding * 6 !important;
		padding-right: $global-padding * 6 !important;
	}
}

.margin-h-large {
	@include breakpoint(large) {
		margin-left: $global-margin !important;
		margin-right: $global-margin !important;
	}

	@include breakpoint(xlarge) {
		margin-left: $global-margin * 3 !important;
		margin-right: $global-margin * 3 !important;
	}

	@include breakpoint(xxlarge) {
		margin-left: $global-margin * 6 !important;
		margin-right: $global-margin * 6 !important;
	}
}

.padding-v-large {
	@include breakpoint(medium) {
		padding-top: $global-padding;
		padding-bottom: $global-padding*2;
	}

	@include breakpoint(large) {
		//padding-top: $global-padding*2;
		padding-bottom: $global-padding*3;
	}
}


// Dimenciones

.fullwidth {
	display: block;
	width: 100%;
	height: auto;
}

.std {
	max-width: $std-width;
}











