@import "variables";

@mixin cg-font-face() {
  font-family: 'cg';
  src:  url('#{$icomoon-font-path}/cg.eot?fekmw3');
  src:  url('#{$icomoon-font-path}/cg.eot?fekmw3#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/cg.ttf?fekmw3') format('truetype'),
    url('#{$icomoon-font-path}/cg.woff?fekmw3') format('woff'),
    url('#{$icomoon-font-path}/cg.svg?fekmw3#cg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  @include cg-font-face;
}

[class^="cg-"], [class*=" cg-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cg' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cg-icon-menu-small {
  &:before {
    content: $cg-icon-menu-small;
  }
}
.cg-icon-menu {
  &:before {
    content: $cg-icon-menu;
  }
}
.cg-icon-search {
  &:before {
    content: $cg-icon-search;
  }
}
.cg-icon-top-small {
  &:before {
    content: $cg-icon-top-small;
  }
}
.cg-icon-right-small {
  &:before {
    content: $cg-icon-right-small;
  }
}
.cg-icon-bottom-small {
  &:before {
    content: $cg-icon-bottom-small;
  }
}
.cg-icon-left-small {
  &:before {
    content: $cg-icon-left-small;
  }
}
.cg-icon-top {
  &:before {
    content: $cg-icon-top;
  }
}
.cg-icon-right {
  &:before {
    content: $cg-icon-right;
  }
}
.cg-icon-bottom {
  &:before {
    content: $cg-icon-bottom;
  }
}
.cg-icon-left {
  &:before {
    content: $cg-icon-left;
  }
}
.cg-icon-close {
  &:before {
    content: $cg-icon-close;
  }
}
.cg-icon-ddd {
  &:before {
    content: $cg-icon-ddd;
  }
}
.cg-icon-circle {
  &:before {
    content: $cg-icon-circle;
  }
}
.cg-icon-info {
  &:before {
    content: $cg-icon-info;
  }
}
.cg-icon-play {
  &:before {
    content: $cg-icon-play;
  }
}
.cg-icon-play-small {
  &:before {
    content: $cg-icon-play-small;
  }
}
.cg-icon-facebook {
  &:before {
    content: $cg-icon-facebook;
  }
}
.cg-icon-twitter {
  &:before {
    content: $cg-icon-twitter;
  }
}
.cg-icon-yelp {
  &:before {
    content: $cg-icon-yelp;
  }
}
.cg-icon-youtube {
  &:before {
    content: $cg-icon-youtube;
  }
}
.cg-icon-google {
  &:before {
    content: $cg-icon-google;
  }
}
.cg-icon-instagram {
  &:before {
    content: $cg-icon-instagram;
  }
}
.cg-icon-linkedin {
  &:before {
    content: $cg-icon-linkedin;
  }
}
.cg-icon-pinterest {
  &:before {
    content: $cg-icon-pinterest;
  }
}
.cg-icon-wordpress {
  &:before {
    content: $cg-icon-wordpress;
  }
}
.cg-icon-pdf {
  &:before {
    content: $cg-icon-pdf;
  }
}
.cg-icon-3mw {
  &:before {
    content: $cg-icon-3mw;
  }
}
.cg-icon-portal {
  &:before {
    content: $cg-icon-portal;
  }
}
.cg-icon-institucion {
  &:before {
    content: $cg-icon-institucion;
  }
}
.cg-icon-ilustrador {
  &:before {
    content: $cg-icon-ilustrador;
  }
}
.cg-icon-escritor {
  &:before {
    content: $cg-icon-escritor;
  }
}
.cg-icon-editorial {
  &:before {
    content: $cg-icon-editorial;
  }
}
.cg-icon-blog {
  &:before {
    content: $cg-icon-blog;
  }
}
.cg-icon-logo-premio {
  &:before {
    content: $cg-icon-logo-premio;
  }
}
.cg-icon-asi-nos-ven {
  &:before {
    content: $cg-icon-asi-nos-ven;
  }
}
.cg-icon-fiesta-lectura-simple {
  &:before {
    content: $cg-icon-fiesta-lectura-simple;
  }
}
.cg-icon-fiesta-lectura {
  &:before {
    content: $cg-icon-fiesta-lectura;
  }
}
.cg-icon-miau-blog {
  &:before {
    content: $cg-icon-miau-blog;
  }
}
.cg-icon-podcast {
  &:before {
    content: $cg-icon-podcast;
  }
}
.cg-icon-premio-cg {
  &:before {
    content: $cg-icon-premio-cg;
  }
}
.cg-icon-quienes-somos {
  &:before {
    content: $cg-icon-quienes-somos;
  }
}
.cg-icon-donar {
  &:before {
    content: $cg-icon-donar;
  }
}


