// Listas

$list-thumbnail-height: (
	small: rem-calc(90px),
	large: rem-calc(120px),
);

$list-thumbnail-width: (
	small: rem-calc(90px),
	large: rem-calc(120px),
);


.list {
	list-style: none;
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;

	.item {
		display: block;
		width: 100%;
		@include clearfix;

		> .inner {
			padding: $global-padding 0;
			border-top: 1px solid $medium-gray;
		}

		.thumbnail {
			display: block;
			height: map-get($list-thumbnail-height, small);

			img {
				height: 100%;
			}

			@include breakpoint (768px) {
				height: map-get($list-thumbnail-height, large);
			}
		}

		.thumb {
			display: block;
			width: map-get($list-thumbnail-width, small);

			@include breakpoint (768px) {
				width: map-get($list-thumbnail-width, large);
			}
			
			.inner {
				@include ratio1-1;
				background-size: contain;
			}
		}

		.item-data {
			min-height: map-get($list-thumbnail-height, small);

			@include breakpoint (768px) {
				min-height: map-get($list-thumbnail-height, large);
			}

			.category {
				display: block;
				font-size: $small-font-size;
				text-transform: uppercase;
				margin: -6px 0 0 0;
			}

			.desc {
				display: block;
				margin: 0;
				padding: 0;
			}
		}

		&.item-thum--left {
			.thumbnail,
			.thumb {
				float: left;
				margin-right: $global-margin;

				.inner {
					background-position: top left;
				}
			}
		}

		&.item-thum--right {
			.thumbnail,
			.thumb {
				float: right;
				margin-left: $global-margin;

				.inner {
					background-position: top right;
				}
			}
		}
	}
}

.list-group {
	.item:first-child {
		> .inner {
			border-top: 0;
		}
	}
}

// Columns
%list-column {
	.item {
		display: inline-block;
		padding: 0 ($global-padding - rem-calc(2px));
		vertical-align: top;
	}
}


.list-column- {
	&2 {
		@extend %list-column;

		.item {
			@include breakpoint (medium) {
				width: 49.5%;
			}
		}
	}

	&3 {
		@extend %list-column;

		.item {
			@include breakpoint (medium) {
				width: 49.5%;
			}

			@include breakpoint (large) {
				width: 33%;
			}
		}
	}
}

.list-detalle {
	.item {
		.inner {
			@include grid-row;
		}

		.item-img {
			@include grid-column(12);
			width: auto;
			padding-left: 0 !important;
			padding-right: 0 !important;

			.thumbnail,
			img {
				width: auto;
				height: auto;
				max-width: auto;
				max-height: auto;
				margin: 0;
			}

			.thumbnail {
				margin: 0 0 $global-margin/2 0;
			}
		}

		.item-data {
			@include grid-column(12);
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		@include breakpoint (medium) {
			.item-img {
				@include grid-column(3 of 8);
				padding-right: $global-margin/2 !important;

				.thumbnail {
					margin: 0;
				}
			}

			.item-data {
				@include grid-column(5 of 8);
				padding-left: $global-margin/2 !important;
			}
		}
	}
}
