// Index


.index {
	list-style: none;
	margin: 0;
	padding: 0 0 $global-padding*2 0;
	@include clearfix;
	overflow: visible;

	.item {
		float: left;
		margin: 0 rem-calc(1px) rem-calc(1px) 0;
		padding: 0;

		.button {
			margin: 0;
			text-align: center;
			padding: .9em;
			background-color: $light-gray;
			color: $black;
			font-size: 1em;

			&:hover {
				background-color: get-color(primary);
			}

			&[disabled] {
				&:hover {
					background-color: $light-gray;
				}
			}

			&.selected {
				color: $white;
				background-color: $black;
			}

			.inner {
				display: block;
				width: 1em;
				@include ratio1-1;
			}
		}
	}
}
